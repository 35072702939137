import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CoreModule } from './../core/core.module';
import {
  FilterComponent,
  FilterConfigService,
  FilterDataService,
  FilterInfoService,
  FilterSliderService,
  ThreeStateCheckboxComponent,
} from '../filter';
import { RDOTooltipOptions, TrialGuardService } from '../core';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { FilterProfileManagementComponent } from './profiles/filter-profile-management/filter-profile-management.component';
import { FilterProfileListComponent } from './profiles/filter-profile-list/filter-profile-list.component';
import { SaveProfileAsButtonsComponent } from './profiles/save-profile-as-btns/save-profile-as.component';
import { FilterProfileService } from './profiles/filter-profile.service';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { SaveProfileAsPopupComponent } from './profiles/save-profile-as-popup/save-profile-as-popup.component';
import { FilterProfileSpinnerComponent } from './profiles/filter-profile-spinner/filter-profile-spinner.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FilterProfileUtilsService } from '@/app/filter/profiles/filter-profile-utils.service';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    CoreModule,
    MatCheckboxModule,
    TooltipModule.forRoot(RDOTooltipOptions as TooltipOptions),
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    FilterComponent,
    ThreeStateCheckboxComponent,
    FilterProfileManagementComponent,
    FilterProfileListComponent,
    SaveProfileAsButtonsComponent,
    SaveProfileAsPopupComponent,
    FilterProfileSpinnerComponent,
  ],
  providers: [
    FilterConfigService,
    FilterDataService,
    FilterInfoService,
    TrialGuardService,
    FilterProfileService,
    FilterSliderService,
    FilterProfileUtilsService,
  ],
  exports: [
    FilterComponent,
    ThreeStateCheckboxComponent,
    FilterProfileListComponent,
    FilterProfileManagementComponent,
  ],
})
export class FilterModule {}
