import { Injectable } from '@angular/core';
import { QueryParams } from '../core/http/query-params';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';

import { PageOptions, SortOptions, ProductQuery } from '../models';

import {
  RdoHttpService,
  ExportSpreadsheetBody,
  QueryService,
  MetricsGridConfig,
  ExcelExportService,
  FormatService,
} from '../core';

import * as filterFunctions from './../filter/functions/filter.functions';
import { FilterInfoService } from './../filter';
import { CODENAMES } from '../core/constants';
import { FilterProfileService } from '../filter/profiles/filter-profile.service';

@Injectable()
export class RentalAssetService {
  private isGetRequest = false;

  constructor(
    private rdoHttp: RdoHttpService,
    private filterInfoService: FilterInfoService,
    private queryService: QueryService,
    private excelExportService: ExcelExportService,
    private formatService: FormatService,
    private filterProfileService: FilterProfileService
  ) {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
  }

  public getProductTypeEquipment = (
    paging: PageOptions = new PageOptions(),
    sorting: SortOptions = new SortOptions(),
    asset: string = null
  ): Observable<any> => {
    const pagedSorted = new ProductQuery({ ClientProductType: null });
    if (asset) {
      pagedSorted.RouseEquipmentID = asset;
    }
    // tslint:disable-next-line: deprecation
    const searchParams = new QueryParams();
    searchParams.set('RouseEquipmentID', asset);
    // Removing this logic to use the filter from profile now.
    // Leaving logic here for now.
    /*searchParams.set(
      'UseRentedAsProductType',
      !!this.filterProfileService.readCurrentProfileProperty(
        CODENAMES.CN_SHOW_RENTED_AS
      )
    );*/
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    return this.queryService.getPagedSorted(
      'metrics/rental-assets/equipment',
      paging,
      sorting,
      this.isGetRequest,
      searchParams,
      pagedSorted
    );
  };

  public getProductTypeEquipmentDownload = (
    gridConfig: MetricsGridConfig,
    sorting: SortOptions = new SortOptions(),
    asset: string = null
  ): Observable<Record<any, any>[]> => {
    const filters = this.filterInfoService.getFilterExport();

    const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), filters);
    const pagedSorted = new ProductQuery({ ClientProductType: null });

    pagedSorted.SetSorting(sorting);

    if (asset) {
      pagedSorted.RouseEquipmentID = asset;
    }

    return this.excelExportService.generateGridSpreadsheet(
      `metrics/product-types/equipment-download/${this.formatService.getDateLocale()}`,
      body,
      pagedSorted
    );
  };

  public getRentalAssetsCards = (
    rouseEquipmentId: number,
    productType: string,
    sort: SortOptions
  ): Observable<any> => {
    const pagedSorted = new ProductQuery({
      RouseEquipmentID: rouseEquipmentId.toString(),
    });
    pagedSorted.SetSorting(sort);
    pagedSorted.Page = 1;
    pagedSorted.PageSize = 150000;

    if (productType) {
      pagedSorted.ClientProductType = productType;
    }

    return this.rdoHttp
      .post('metrics/rental-assets/cards', pagedSorted)
      .pipe(map((res) => res));
  };

  public getDashboardCardData = (equipmentId: number): Observable<any> => {
    const pagedSorted = new ProductQuery();
    pagedSorted.RouseEquipmentID = equipmentId.toString();
    pagedSorted.Page = 1;
    pagedSorted.PageSize = 1;

    return this.rdoHttp
      .post('metrics/rental-assets', pagedSorted)
      .pipe(map((res) => res));
  };
}
