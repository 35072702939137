import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  static genericErrorMessage = 'Unknown Error';

  errorRaised = new Subject<string>();

  setError(message: string) {
    this.errorRaised.next(message);
  }

  public logSentryError(error: any, message?: string) {
    try {
      if (error instanceof HttpErrorResponse) {
        Sentry.captureException(error);
      } else {
        message
          ? Sentry.captureException(message, error)
          : Sentry.captureException(error);
      }
    } catch (error) {
      debugger;
      console.error('Error from logSentryError', error.message);
    }
  }

  handleError = (error: any) => {
    this.logSentryError(error);

    console.error('Error from global error handler', error.message);
    this.setError(error.message);
  };
}
