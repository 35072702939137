import { Injectable } from '@angular/core';
import { QueryParams } from '../http/query-params';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { PagedQuery, GeographyQuery } from '../../models';
import { RdoHttpService } from './../../core/http/rdo-http.service';
//TODO PRS DEDUPE
export class PageOptions {
  public page: number = 1;
  public pageSize: number = 20;
}

export class SortOptions {
  constructor(
    public sortOn: string = null,
    public descending: boolean = false
  ) {}
}
@Injectable()
export class QueryService {
  constructor(protected rdoHttp: RdoHttpService) {}

  getPagedSorted = (
    url: string,
    paging: PageOptions,
    sorting: SortOptions,
    isGetRequest: boolean,
    params?: QueryParams,
    query?: PagedQuery,
    except?: string[]
  ): Observable<any> => {
    if (isGetRequest) {
      params = params || new QueryParams();
      params.set('pageSize', paging.pageSize.toString());
      params.set('page', paging.page.toString());

      this.setSortingParams(params, sorting);

      if (except !== undefined) {
        return this.rdoHttp
          .getfx(except, url, { search: params })
          .pipe(map((res) => res));
      } else {
        return this.rdoHttp
          .getf(url, { search: params })
          .pipe(map((res) => res));
      }
    } else {
      if (!query) {
        query = new PagedQuery();
      }

      query.PageSize = paging.pageSize;
      query.Page = paging.page;
      //query.groupByCustomer = groupByCustomer,
      //query.groupByProductType = groupByProductType;
      query.SetSorting(sorting);

      return this.rdoHttp
        .post(url, query, undefined, undefined, except)
        .pipe(map((res) => res));
    }
  };

  getSorted = (
    url: string,
    sorting: SortOptions,
    isGetRequest: boolean,
    params?: QueryParams,
    query?: PagedQuery | GeographyQuery,
    except?: string[]
  ): Observable<any> => {
    if (isGetRequest) {
      params = params || new QueryParams();
      this.setSortingParams(params, sorting);
      if (except && except.length) {
        return this.rdoHttp
          .getfx(except, url, { search: params })
          .pipe(map((res) => res));
      } else {
        return this.rdoHttp
          .getf(url, { search: params })
          .pipe(map((res) => res));
      }
    } else {
      if (!query) {
        query = new PagedQuery();
      }

      if (query.SetSorting) {
        query.SetSorting(sorting);
      }

      return this.rdoHttp.post(url, query).pipe(map((res) => res));
    }
  };

  getfxPagedSorted = (
    exclude: string[],
    url: string,
    paging: PageOptions,
    sorting: SortOptions,
    isGetRequest: boolean,
    params?: QueryParams,
    query?: PagedQuery
  ): Observable<any> => {
    if (isGetRequest) {
      params = params || new QueryParams();
      params.set('pageSize', paging.pageSize.toString());
      params.set('page', paging.page.toString());

      this.setSortingParams(params, sorting);

      return this.rdoHttp
        .getfx(exclude, url, { search: params })
        .pipe(map((res) => res));
    } else {
      if (!query) {
        query = new PagedQuery();
      }

      query.PageSize = paging.pageSize;
      query.Page = paging.page;
      query.SetSorting(sorting);

      return this.rdoHttp.post(url, query).pipe(map((res) => res));
    }
  };

  getfxSorted = (
    exclude: string[],
    url: string,
    sorting: SortOptions,
    isGetRequest: boolean,
    params?: QueryParams,
    query?: PagedQuery | GeographyQuery
  ): Observable<any> => {
    if (isGetRequest) {
      params = params || new QueryParams();
      this.setSortingParams(params, sorting);

      return this.rdoHttp
        .getfx(exclude, url, { search: params })
        .pipe(map((res) => res));
    } else {
      if (!query) {
        query = new PagedQuery();
      }

      if (query.SetSorting) {
        query.SetSorting(sorting);
      }

      return this.rdoHttp.post(url, query).pipe(map((res) => res));
    }
  };

  setSortingParams = (params: QueryParams, sort: SortOptions) => {
    if (sort.sortOn) {
      params.set('OrderBy', sort.sortOn);
    }

    params.set('OrderByDirection', sort.descending ? 'DESC' : 'ASC');
  };
}
