function paramParser(rawParams = '') {
  /** @type {?} */
  const map = new Map();
  if (rawParams.length > 0) {
    /** @type {?} */
    const params = rawParams.split('&');
    params.forEach((param) => {
      /** @type {?} */
      const eqIdx = param.indexOf('=');
      const [key, val] =
        eqIdx === -1
          ? [param, '']
          : [param.slice(0, eqIdx), param.slice(eqIdx + 1)];
      /** @type {?} */
      const list = map.get(key) || [];
      list.push(val);
      map.set(key, list);
    });
  }
  return map;
}

function standardEncoding(v) {
  return encodeURIComponent(v)
    .replace(/%40/gi, '@')
    .replace(/%3A/gi, ':')
    .replace(/%24/gi, '$')
    .replace(/%2C/gi, ',')
    .replace(/%3B/gi, ';')
    .replace(/%2B/gi, '+')
    .replace(/%3D/gi, '=')
    .replace(/%3F/gi, '?')
    .replace(/%2F/gi, '/');
}

export class QueryEncoder {
  encodeKey(key) {
    return standardEncoding(key);
  }

  encodeValue(value) {
    return standardEncoding(value);
  }
}

export class QueryParams {
  public rawParams: any;
  public queryEncoder: any;
  public paramsMap: any;
  constructor(rawParams = '', queryEncoder = new QueryEncoder()) {
    this.rawParams = rawParams;
    this.queryEncoder = queryEncoder;
    this.paramsMap = paramParser(rawParams);
  }

  clone() {
    /** @type {?} */
    const clone = new QueryParams('', this.queryEncoder);
    clone.appendAll(this);
    return clone;
  }

  has(param) {
    return this.paramsMap.has(param);
  }

  get(param) {
    /** @type {?} */
    const storedParam = this.paramsMap.get(param);
    return Array.isArray(storedParam) ? storedParam[0] : null;
  }

  set(param, val) {
    if (val === void 0 || val === null) {
      this.delete(param);
      return;
    }
    /** @type {?} */
    const list = this.paramsMap.get(param) || [];
    list.length = 0;
    list.push(val);
    this.paramsMap.set(param, list);
  }

  append(param, val) {
    if (val === void 0 || val === null) {
      return;
    }
    /** @type {?} */
    const list = this.paramsMap.get(param) || [];
    list.push(val);
    this.paramsMap.set(param, list);
  }

  appendAll(searchParams) {
    searchParams.paramsMap.forEach((value, param) => {
      /** @type {?} */
      const list = this.paramsMap.get(param) || [];
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < value.length; ++i) {
        list.push(value[i]);
      }
      this.paramsMap.set(param, list);
    });
  }

  replaceAll(searchParams) {
    searchParams.paramsMap.forEach((value, param) => {
      /** @type {?} */
      const list = this.paramsMap.get(param) || [];
      list.length = 0;
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < value.length; ++i) {
        list.push(value[i]);
      }
      this.paramsMap.set(param, list);
    });
  }

  delete(param) {
    this.paramsMap.delete(param);
  }

  toString(): string {
    const obj = Object.fromEntries(this.paramsMap);
    const json = JSON.stringify(obj);
    return json;
  }
}
