import { Injectable } from '@angular/core';

import { map, switchMap } from 'rxjs/operators';

import { ProductQuery } from './../../../models';
import { FilterInfoService } from '../../../filter/services/filter-info.service';
import * as _ from 'lodash';

import {
  RdoHttpService,
  QueryService,
  ViewService,
  ComparisonModeSortOptions,
  ActiveFilterService,
} from './../../../core';

@Injectable()
export class ProductTypeDashboardSalesRepService {
  constructor(
    private viewService: ViewService,
    private queryService: QueryService,
    private filterInfoService: FilterInfoService,
    private filterService: ActiveFilterService,
    private rdoHttp: RdoHttpService
  ) {}

  public getTopSalesReps(productType: string, category?: string) {
    return this.viewService
      .getComparisonModeSortOptions(ComparisonModeSortOptions.Difference)
      .pipe(
        switchMap((sorting) => {
          const pagedSorted = new ProductQuery();
          pagedSorted.ClientProductType = productType;
          if (category) {
            pagedSorted.RouseCategoryList = [
              this.filterInfoService.getRouseCategoryId(category),
            ];
          }

          pagedSorted.SetSorting(sorting);

          return this.rdoHttp
            .post('metrics/product-types/dashboard/sales-reps', pagedSorted)
            .pipe(
              map((r: any) => {
                return r;
              })
            );
        })
      );
  }
}
