import { Component, OnInit, signal } from '@angular/core';
import { AuthenticationService } from './core/authentication/authentication.service';
import { environment } from '../environments/environment';
import {
  SvgService,
  ConfigService,
  LocaleService,
  IntroJsService,
} from './core';
import times from 'lodash/times';
import { mergeMap, map, filter } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd, Data } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr-CA';
import localeDe from '@angular/common/locales/de';
import localeJp from '@angular/common/locales/ja';
import localeGb from '@angular/common/locales/en-GB';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import * as _ExportData from 'highcharts/modules/export-data';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import * as Sentry from '@sentry/angular';
import { datadogRum } from '@datadog/browser-rum';
import { ClientBrandingService } from '@/app/core/client/client-branding.service';

HighchartsBoost(Highcharts);
HighchartsNoData(Highcharts);
HighchartsMore(Highcharts);

HighchartsExportData(Highcharts);
HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public selectedClientId: number = null;
  public _containervisibility = 'invisible';
  public title = 'analytics-rdo-ui';
  private currentRouteData: Data;
  private languages: string[];

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private svgService: SvgService,
    private configService: ConfigService,
    public translateService: TranslateService,
    private localeService: LocaleService,
    private introService: IntroJsService,
    public clientBrandingService: ClientBrandingService
  ) {
    // implicit flow requires that 'handleauthentication' is triggered upon load, pkce manages callback in the
    // callback component directly
    if (environment.isPkceFlowMode === false) {
      this.authenticationService.handleAuthentication();
    }
    this.introService.initService();
  }

  public ngOnInit(): void {
    // load the application
    this.applyTheme();
    this.preload();
    // this.fixIESafariHistoryNavigation();
    this.applyTitleChange();

    // wait until the client is known, this includes multi-client selection from user
    this.authenticationService.selectedClientId.subscribe(
      (selectedclientid: number) => {
        if (
          this.authenticationService._userInfoView &&
          this.authenticationService._userInfoView
            .ImpersonatedOrCurrentUserEmail
        ) {
          const userName = this.authenticationService._userInfoView
            .ImpersonatedOrCurrentUserEmail
            ? this.authenticationService._userInfoView
                .ImpersonatedOrCurrentUserEmail
            : '';
          const userId = this.authenticationService._userInfoView.Id
            ? this.authenticationService._userInfoView.Id.toString()
            : '';
          const clientCode = this.authenticationService._userInfoView
            .SelectedClient
            ? this.authenticationService._userInfoView.SelectedClient.ClientCode
            : '';
          datadogRum.setUser({
            email: userName,
            id: userId,
            clientCode: clientCode,
          });
          Sentry.setUser({ username: userName });
          if (this.authenticationService.enablePerformanceMonitoring()) {
            const client = Sentry.getClient();
            client.getOptions().enabled = false;
            client.getOptions().tracesSampleRate = 1.0;
            client.getOptions().enabled = true;
          }
        }

        if (selectedclientid !== undefined && selectedclientid !== null) {
          this.authenticationService.Authenticated.subscribe((isAuth) => {
            if (
              isAuth &&
              !this.authenticationService._userInfoView
                ?.IsRouseTestUserImpersonator
            ) {
              const s = document.createElement('script');
              s.type = 'text/javascript';
              s.text = `
                        window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};
                        var r=document.createElement("script");
                        r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";
                        var a=document.getElementsByTagName("script")[0]; 
                        a.parentNode.insertBefore(r,a);
                        for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                        try {
                            heap.load("${environment.heapId}");
                            heap.identify("${this.authenticationService._userInfoView.Id}");
                            heap.addUserProperties({
                                RdoIdentityUserId: "${this.authenticationService.tokenInfo.AccessTokenDecoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']}",
                                RdoUserId: "${this.authenticationService._userInfoView.Id}",
                                RdoClientId: "${this.authenticationService._userInfoView.SelectedClient.ClientID}",
                                RdoClientCode: "${this.authenticationService._userInfoView.SelectedClient.ClientCode}"
                            });
                        } catch (e) {
                            console.error(e.message);
                        }`;
              document.getElementsByTagName('head')[0].appendChild(s);
            }
          });
        }
      }
    );

    // Only load translations after login.
    if (window.location.href.includes('callback')) {
      this.translationInit();
    }

    // Setup tooltip container.
    setInterval(() => {
      const tooltips = document.getElementsByClassName('tooltip mat-tooltip');
      if (tooltips.length > 0) {
        const tooltip = tooltips[0];
        if (
          parseFloat((<any>tooltip).style.left.replace('px', '')) >
          window.screen.width - 300
        ) {
          (<any>tooltip).style.left =
            (window.screen.width - 300).toString() + 'px';
        } else if (
          parseFloat((<any>tooltip).style.left.replace('px', '')) < 0
        ) {
          (<any>tooltip).style.left = '5px';
        }
      }
    }, 75);
  }

  translationInit() {
    // Add supported languages (besides en-US)
    registerLocaleData(localeFr, 'fr-CA');
    registerLocaleData(localeDe, 'de-DE');
    registerLocaleData(localeJp, 'ja-JP');
    registerLocaleData(localeGb, 'en-GB');
    this.languages = ['en-US', 'fr-CA', 'de-DE', 'ja-JP', 'en-GB'];
    this.translateService.addLangs(this.languages);
    this.authenticationService.Authenticated.subscribe((isAuth) => {
      if (isAuth) {
        let browserLang = this.translateService.getBrowserLang();
        browserLang = browserLang.match(/en-US|fr-CA/) ? browserLang : 'en-US';
        const gotLang =
          this.authenticationService &&
          this.authenticationService._userInfoView &&
          this.authenticationService._userInfoView.lang;
        const language = gotLang
          ? this.authenticationService._userInfoView.lang
          : this.localeService.getLocale() || browserLang;
        this.localeService.setLocale(language);
      }
    });
    this.localeService.observableLocale.subscribe((lang) => {
      const currentLocaleAccordingToTranslateService =
        this.translateService.currentLang;
      if (lang && lang !== currentLocaleAccordingToTranslateService) {
        this.translateService.use(lang);
        // This causes a double load of the locale file. Commenting out removes the second load of the language and doesn't
        // seem to have any negative effects. What was this here for????
        // this.translateService.reloadLang(lang).subscribe();
      }
    });
    this.translateService.onLangChange.subscribe((event: any) => {
      const defaultTitle =
        this.currentRouteData && this.currentRouteData.title
          ? this.currentRouteData.title
          : undefined;
      if (defaultTitle) {
        this.configService.setTitle(defaultTitle);
      }
    });
  }

  public getContainerVisibility = (): string => {
    let result: string = 'invisible';
    if (
      this.authenticationService._userInfoView &&
      this.authenticationService._userInfoView.SelectedClient &&
      this.authenticationService._userInfoView.SelectedClient.ClientID
    ) {
      result = 'visible';
    }
    return result;
  };

  private applyTheme = () => {
    this.clientBrandingService.isReadySubject.subscribe((isReady) => {
      if (!isReady) {
        return;
      }

      // Add a class to the body to respond to theme having a url globally.
      if (this.clientBrandingService.logoUrl()) {
        document.querySelector('body').classList.add('client-logo-present');
      }

      const theme = {
        colors: [
          '#7CB5EC',
          '#F7A35C',
          '#90EE7E',
          '#7798BF',
          '#AAEEEE',
          '#FF0066',
          '#EEAAEE',
          '#55BF3B',
          '#DF5353',
          '#7798BF',
          '#AAEEEE',
        ],
        chart: {
          backgroundColor: null,
          style: {
            fontFamily: "'Roboto', sans-serif",
          },
        },
        title: {
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          },
          text: '',
        },
        tooltip: {
          borderWidth: 0,
          backgroundColor: 'rgba(219,219,216,0.8)',
          shadow: false,
        },
        lang: {
          numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'],
          thousandsSep: ',',
          noData: null,
        },
        legend: {
          itemStyle: {
            fontWeight: 'bold',
            fontSize: '12px',
          },
          symbolRadius: 0,
        },
        xAxis: {
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          labels: {
            style: {
              fontSize: '12px',
            },
          },
        },
        yAxis: {
          //minorTickInterval: 'auto',
          title: {
            style: {
              textTransform: 'uppercase',
            },
          },
          labels: {
            style: {
              fontSize: '12px',
            },
          },
        },
        plotOptions: {
          candlestick: {
            lineColor: '#404048',
          },
          column: {
            shadow: false,
            borderWidth: 0,
          },
          series: {
            cursor: 'pointer',
            animation: {
              duration: 500,
            },
          },
        },
        credits: {
          enabled: false,
        },
      };

      // Apply the theme
      Highcharts.setOptions(theme);
    });
  };

  // todo: refactor all inline html and styles
  private preload = () => {
    // preload percentage svgs
    times(21).forEach((x) => {
      this.svgService
        .getIconMarkup(`/assets/images/pie/${x * 5}.svg`)
        .subscribe((_) => {
          // do nothing
        });
    });
  };

  private applyTitleChange = () => {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.currentRouteData = event;
        if (event.title) {
          this.configService.setTitle(event.title);
        }
      });
  };
}
