import { Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CustomGridsComponent } from '../custom-grids.component';
import { ExportSchedule } from './export-schedule';
import { ExportSchedulerService } from './export-scheduler.service';
import * as Sentry from '@sentry/angular';
import { DateAdapter } from '@angular/material/core';
import { ErrorService, FormatService, LocaleService } from '../../core';
import { FileManagerComponent } from '../../core/file-manager';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { NotificationParameters } from './../../models/notification-parameters';
import { CustomGridsService } from '../custom-grids.service';
import { HttpErrorResponse } from '@angular/common/http';
/* eslint-disable @typescript-eslint/unbound-method */
@Component({
  selector: 'rdo-export-scheduler',
  templateUrl: './export-scheduler.component.html',
  styleUrls: ['./export-scheduler.component.scss'],
})
export class ExportSchedulerComponent implements OnInit {
  @Input()
  parent: CustomGridsComponent;

  @ViewChild('fileManagerComponent')
  public fileManagerComponent: FileManagerComponent;

  private readonly _adapter =
    inject<DateAdapter<unknown, unknown>>(DateAdapter);
  title = this.translationService.instant(
    'main.tabs.custom_grids.toolbar.export_schedule_title'
  );
  isVisible = false;
  isLoading = false;
  scheduleForm = new UntypedFormGroup({});
  schedule: ExportSchedule = new ExportSchedule();
  submitted = false;
  SuccessMessage = this.translationService.instant(
    `main.tabs.custom_grids.scheduled_exports.messages.successfully_saved`
  );
  cancelSuccessMessage = this.translationService.instant(
    `main.tabs.custom_grids.scheduled_exports.messages.cancel_success`
  );
  errorMessage = this.translationService.instant(
    `main.tabs.custom_grids.scheduled_exports.messages.error`
  );
  loadingErrorMessage = this.translationService.instant(
    `main.tabs.custom_grids.scheduled_exports.messages.loading_error`
  );
  confirmCancelMessage = this.translationService.instant(
    `main.tabs.custom_grids.scheduled_exports.messages.confirm_cancel`
  );
  isSubmitting = false;
  dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;
  gridId: string = '';
  gridSource: string = '';
  isAdmin: boolean;
  hasToManyRows: boolean;
  disableSaveByFileManager: boolean;
  showFileManager: boolean = false;

  public usersConfig: any;
  get startDate() {
    return this.scheduleForm.get('startDate');
  }
  get frequency() {
    return this.scheduleForm.get('frequency');
  }

  constructor(
    private localeService: LocaleService,
    private dateAdapter: DateAdapter<any>,
    private translationService: TranslateService,
    private authenticationService: AuthenticationService,
    private exportSchedulerService: ExportSchedulerService,
    private customGridsService: CustomGridsService,
    private formatService: FormatService,
    private errorService: ErrorService
  ) {
    this.showFileManager =
      this.authenticationService.userIsOrImpersonatesCGEditor() &&
      this.authenticationService.hasGeographyFullAccess();
  }
  ngOnInit() {
    this.setUsersConfig();
    const localeFormat = this.formatService.getLocaleFormat();
    this.dateAdapter.setLocale(localeFormat);
    this._adapter.setLocale(localeFormat ? localeFormat.LocaleID : 'en-US');
  }

  setUsersConfig() {
    this.usersConfig = {
      RestrictedUsers: [],
      useSmallerLists: true,
      avoidApiInteractions: true,
      addCurrentUserAsRestricted:
        !this.schedule || !this.schedule.ScheduledExportId,
      notificationUserIds:
        this.schedule?.ScheduleNotification.notificationUserIds,
      notificationMsg: this.schedule?.ScheduleNotification.CustomMessage,
      currentGridBranches: this.customGridsService.getCurrentBranches(),
    };
  }

  showScheduleDialog = (
    gridId: string,
    gridSource: string,
    exportSchedule: ExportSchedule,
    isAdmin: boolean,
    hasToManyRows: boolean
  ) => {
    this.isLoading = true;
    this.gridId = gridId;
    this.gridSource = gridSource;
    this.isAdmin = isAdmin;
    this.hasToManyRows = hasToManyRows;

    this.scheduleForm = new UntypedFormGroup({
      startDate: new UntypedFormControl('', [Validators.required]),
      frequency: new UntypedFormControl('', [Validators.required]),
    });
    this.isVisible = true;
    this.schedule = exportSchedule;
    this.setUsersConfig();
    if (!exportSchedule) {
      this.exportSchedulerService
        .getGridExportSchedule(gridId, gridSource)
        .subscribe(
          (r: ExportSchedule) => {
            this.loadScheduleFromResponse(r);
          },
          (err: HttpErrorResponse) => {
            this.parent.showModalDialog(this.loadingErrorMessage, 'Error');
            this.isLoading = false;
            this.isVisible = false;
            this.errorService.logSentryError(
              err,
              'exportSchedulerService.getGridExportSchedule failed:'
            );
          }
        );
    } else {
      this.setFormDetails();
    }
  };

  loadScheduleFromResponse(response: any) {
    this.schedule = response
      ? this.exportSchedulerService.transformJsonToExportSchedule(response)
      : new ExportSchedule();
    if (!this.schedule?.ScheduleUserPermissions?.length) {
      this.fileManagerComponent?.unSelectAll();
    }
    this.setUsersConfig();
    this.setFormDetails();
  }

  setFormDetails() {
    if (!this.schedule.ScheduledExportId) {
      this.schedule.GridFirestoreId = this.gridId;
      this.schedule.GridType = this.gridSource;
    }
    this.scheduleForm.get('startDate').setValue(this.schedule.StartDate);
    this.scheduleForm.get('frequency').setValue(this.schedule.Frequency);
    this.isLoading = false;
    this.isSubmitting = false;
    this.submitted = false;
  }

  getStartDate(startDate: Date) {
    return startDate ? this.getShortDateFromDate(startDate) : new Date();
  }

  getShortDateFromDate(theDate: Date) {
    if (theDate) {
      return this.formatService.formatDate(theDate, 'M/d/YYYY');
    }
    return null;
  }

  closeMessageDialog() {
    this.isVisible = false;
  }

  onSubmit() {
    if (this.hasToManyRows) {
      this.parent.showModalDialog(
        this.translationService.instant(
          'main.tabs.custom_grids.toolbar.excessive_rows_msg'
        )
      );
      return;
    }
    this.submitted = true;
    this.isSubmitting = true;
    if (this.scheduleForm.valid) {
      this.schedule.StartDate = this.scheduleForm.get('startDate').value;
      this.schedule.Frequency = this.scheduleForm.get('frequency').value;
      const notifParams: NotificationParameters =
        this.fileManagerComponent?.getNotificationParameters();
      this.exportSchedulerService
        .setGridGridExportSchedule(
          this.schedule.ScheduledExportId,
          this.schedule.GridFirestoreId,
          this.schedule.GridType,
          this.schedule.StartDate,
          this.schedule.Frequency,
          this.fileManagerComponent?.getSelectedUserIds() || [],
          notifParams.notificationUserIds,
          notifParams.notificationMsg
        )
        .subscribe(
          (r: ExportSchedule) => {
            this.parent.showModalDialog(
              this.SuccessMessage,
              this.translationService.instant(
                'main.tabs.custom_grids.page_labels.success'
              )
            );
            this.loadScheduleFromResponse(r);
            this.parent.getScheduleExport(this.gridId);
          },
          (err: HttpErrorResponse) => {
            this.parent.showModalDialog(
              this.errorMessage,
              this.translationService.instant(
                'main.tabs.custom_grids.page_labels.error'
              )
            );
            this.errorService.logSentryError(
              err,
              'exportSchedulerService.setGridGridExportSchedule failed:'
            );
          }
        );
    } else {
      this.isSubmitting = false;
    }
  }

  onCancelSchedule() {
    if (confirm(this.confirmCancelMessage)) {
      this.isSubmitting = true;
      this.exportSchedulerService
        .deleteGridGridExportSchedule(this.schedule.ScheduledExportId)
        .subscribe(
          (r) => {
            this.loadScheduleFromResponse(null);
            this.parent.showModalDialog(
              this.cancelSuccessMessage,
              this.translationService.instant(
                'main.tabs.custom_grids.page_labels.success'
              )
            );
            this.parent.getScheduleExport(this.gridId);
            this.fileManagerComponent?.restartParameters();
          },
          (err: Error) => {
            this.parent.showModalDialog(
              this.errorMessage,
              this.translationService.instant(
                'main.tabs.custom_grids.page_labels.error'
              )
            );
            this.isSubmitting = false;
            this.errorService.logSentryError(
              err,
              'exportSchedulerService.deleteGridGridExportSchedule failed:'
            );
          }
        );
    }
  }

  disableSubmitBtn() {
    const formDataIsValid =
      !!this.scheduleForm.get('startDate').value &&
      !!this.scheduleForm.get('frequency').value;
    return (
      this.isSubmitting || this.disableSaveByFileManager || !formDataIsValid
    );
  }

  public onFileSaveChange(shouldDisable) {
    this.disableSaveByFileManager = shouldDisable;
  }
}
