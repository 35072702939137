import { Injectable, EventEmitter } from '@angular/core';
import { QueryParams } from '../http/query-params';
import { Observable } from 'rxjs'; //Observable';
import { Subject } from 'rxjs'; //Subject';
import { of } from 'rxjs'; //observable/of';
import { filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { ProfileService } from './../user/profile.service';
import { ClientMonth } from './../../models/client-month';
import { FilterValues } from './../../models/filter-values';
import { FilterBroadcastType } from './../../models/filter-values';
import { MultiSelectDateOption } from './../../models/multiselectdateoption';
import { SearchQuery } from './../../models/body';
import { FilterProfileService } from '../../filter/profiles/filter-profile.service';
import { FilterProfileMap } from '../../filter/profiles/models/filter-profile-map';
import { FilterProfile } from '../../filter/profiles/models/filter-profile';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

type PostFilterPlugin = (searchQuery: SearchQuery) => SearchQuery;

@Injectable()
export class ActiveFilterService {
  private currentFilter: FilterValues;
  private filterChangeEmitter: EventEmitter<FilterValues> =
    new EventEmitter<FilterValues>();
  public alertFilterChange$: EventEmitter<FilterValues> =
    new EventEmitter<FilterValues>();
  private subject: Subject<SearchQuery> = new Subject<SearchQuery>();
  private subjectForGet: Subject<QueryParams> = new Subject<QueryParams>();
  private filter: SearchQuery;
  private params: QueryParams;
  public clearFiltersSubject: Subject<string[]> = new Subject<string[]>();
  public clientMonth: ClientMonth;
  public dateOptions: Array<MultiSelectDateOption>;
  private defaultOutlierId: number;
  private defaultVertical: number;
  public clientId: string;
  private filterTransformationPlugins: PostFilterPlugin[] = [
    this.getEquipmentPostFilters.bind(this),
  ];
  private routeParamMap: any;

  constructor(
    private profileService: ProfileService,
    private filterProfileService: FilterProfileService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.defaultVertical = -1;
    this.defaultOutlierId = -1;
    this.filterProfileService.currentProfile.subscribe((profile) => {
      if (FilterProfileMap.validateProfile(profile, true)) {
        this.currentFilter = FilterProfile.asFilterValues(profile);
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary')
      )
      .subscribe((route) => {
        let routeparent = null; // trying to circumvent null / undefined errors
        try {
          routeparent = route.parent;
        } catch (ex) {
          const s = ex;
        }
        if (routeparent !== null) {
          this.routeParamMap = route.parent.snapshot.params;
        }
      });
  }

  getFilterParams(): SearchQuery {
    let filters = this.copy(this.filter);
    this.filterTransformationPlugins.forEach((plugin) => {
      filters = plugin(filters);
    });
    return filters;
  }

  get filterParams(): Observable<SearchQuery> {
    if (this.filter) {
      let filters = this.copy(this.filter);
      this.filterTransformationPlugins.forEach((plugin) => {
        filters = plugin(filters);
      });
      return of(filters);
    }

    return this.subject;
  }

  get filterParamsForGet(): Observable<QueryParams> {
    if (this.params) {
      return of(this.params);
    }

    return this.subjectForGet;
  }

  public getCurrentFilter = (): FilterValues => {
    this.defaultOutlierId =
      this.currentFilter &&
      this.currentFilter.outlierReasons &&
      this.defaultOutlierId === -1
        ? this.currentFilter.outlierReasons[0]
        : this.defaultOutlierId;
    this.defaultVertical =
      this.currentFilter &&
      this.currentFilter.verticals &&
      this.defaultVertical === -1
        ? this.currentFilter.verticals[0]
        : this.defaultVertical;
    const result = this.copy(this.currentFilter);
    const routeUseRentedAsProductType: any =
      this.routeParamMap.useRentedAsProductType;

    let useRentedAsProductType = result.useRentedAsProductType;
    if (routeUseRentedAsProductType) {
      useRentedAsProductType =
        routeUseRentedAsProductType === 'true' ? true : false;
    } else {
      useRentedAsProductType = result.useRentedAsProductTypes === 1;
    }
    result.useRentedAsProductType = useRentedAsProductType;

    return result;
  };

  get filterChange(): EventEmitter<FilterValues> {
    return this.filterChangeEmitter;
  }

  public alertFilterChange = (filterValues: FilterValues) => {
    this.alertFilterChange$.emit(filterValues);
  };

  public getDefaultOutlierId = (): number => {
    return this.defaultOutlierId;
  };

  public getDefaultVertical = (): number => {
    return this.defaultVertical;
  };

  updateFilter = (
    f: FilterValues,
    filterBroadcastType = FilterBroadcastType.None
  ) => {
    if (this.dateOptions && this.dateOptions.length) {
      this.currentFilter = this.copy(f);
      this.ensureDateTypes();
      this.setMonthIdStartAndEnd();
      this.params = this.createSearchParamsForGet(this.currentFilter);
      this.filter = this.createSearchParams(this.currentFilter);

      switch (filterBroadcastType) {
        case FilterBroadcastType.Alert:
          this.alertFilterChange(f);
          return;
        case FilterBroadcastType.Global:
          this.broadcastGlobally();
          return;
        default:
          return;
      }
    }
  };

  setMultiClientId = (multiclientid: string) => {
    this.params.delete('ClientId');
    this.params.append('ClientId', multiclientid);
    if (this.filter) {
      this.filter.ClientId = multiclientid;
    }
    this.clientId = multiclientid;
  };

  private ensureDateTypes(): void {
    this.currentFilter.dateFrom =
      typeof this.currentFilter.dateFrom === 'string' ||
      this.currentFilter.dateFrom instanceof String
        ? new Date(this.currentFilter.dateFrom)
        : this.currentFilter.dateFrom;
    this.currentFilter.dateTo =
      typeof this.currentFilter.dateTo === 'string' ||
      this.currentFilter.dateTo instanceof String
        ? new Date(this.currentFilter.dateTo)
        : this.currentFilter.dateTo;
  }

  private setMonthIdStartAndEnd = () => {
    this.currentFilter.monthFromId = Math.min(
      ...this.filterProfileService.selectedMonthIds
    );
    this.currentFilter.monthToId = Math.max(
      ...this.filterProfileService.selectedMonthIds
    );
    // const md = this.currentFilter.dateTo.getMonth() - this.currentFilter.dateFrom.getMonth();
    // const yd = this.currentFilter.dateTo.getFullYear() - this.currentFilter.dateFrom.getFullYear();
    // const monthDiff = md + (12 * yd);
    // this.refreshDateOptions();
    // this.filterProfileService.reconcileSelectedMonthsWithAvailableMonths(this.dateOptions, this.filterProfileService.selectedMonthIds,
    // 	FilterProfileMap.getCurrentMonthId(), this.filterProfileService.currentProfile.value.monthsCode)
    // debugger;
    // const dateFromSelected = _.find(this.dateOptions, dateOption => {
    // 	return dateOption.date.toString() === this.currentFilter.dateFrom.toString();
    // });

    // this.currentFilter.monthFromId = dateFromSelected.id;

    // if (monthDiff === 1) {
    // 	this.currentFilter.monthToId = this.currentFilter.monthFromId;
    // } else {
    // 	const dateToSelected = _.find(this.dateOptions, dateOption => {
    // 		return dateOption.date.toString() === this.currentFilter.dateTo.toString();
    // 	});

    // 	if (dateToSelected) {
    // 		this.currentFilter.monthToId = dateToSelected.id - 1;
    // 	} else {
    // 		this.currentFilter.monthToId = _.max(this.dateOptions).id;
    // 	}
    // }
  };

  /**
   * Sometimes dates within dateOptions get changed
   * so it's necessary to refresh them.
   */
  private refreshDateOptions() {
    if (this.dateOptions && this.dateOptions.length) {
      this.dateOptions.forEach((dateOption) => {
        dateOption.date = FilterProfile.monthId2Date(dateOption.id);
      });
    }
  }

  private broadcastGlobally = () => {
    if (this.subject) {
      let filters = this.copy(this.filter);
      this.filterTransformationPlugins.forEach((plugin) => {
        filters = plugin(filters);
      });
      this.subject.next(filters);
      this.subject = null;
    } else {
      this.filterChange.next(this.currentFilter);
      this.profileService.saveOptions('FILTER', this.currentFilter);
    }

    if (this.subjectForGet) {
      this.subjectForGet.next(this.params);
      this.subjectForGet = null;
    }
  };

  getDateRangeFromSelectedMonthIds = (
    selectedMonthIds: Array<number>
  ): [Date, Date] => {
    let dateFrom: Date;
    let dateTo: Date;

    if (selectedMonthIds.length === 1) {
      let rawDateFrom = _.find(this.dateOptions, (dateOption) => {
        return dateOption.id === selectedMonthIds[0];
      });
      if (!rawDateFrom) {
        // If the db is outdated, the selected date Id will be newer than the available dates in the dateOptions.
        // Being that the case, the latest months available are selected based on the amount of selectedMonthIds.
        const monthdsAmount = selectedMonthIds.length;
        const dateOpts = JSON.parse(JSON.stringify(this.dateOptions));
        dateOpts.sort((a, b) => {
          return a.id < b.id ? -1 : 1;
        });
        const actualSelectedMonths = dateOpts.slice(0, monthdsAmount);
        rawDateFrom = actualSelectedMonths[0];
      }
      rawDateFrom.date = FilterProfile.monthId2Date(rawDateFrom.id);
      dateFrom = rawDateFrom.date;
      dateTo = new Date(dateFrom.toString());
    } else {
      dateFrom = this.readFilteredDateSafely(
        this.dateOptions,
        selectedMonthIds,
        /* eslint-disable-next-line */
        _.min
      );
      dateTo = this.readFilteredDateSafely(
        this.dateOptions,
        selectedMonthIds,
        /* eslint-disable-next-line */
        _.max
      );
    }
    dateTo.setMonth(dateTo.getMonth() + 1);
    return [dateFrom, dateTo];
  };

  public readFilteredDateSafely(
    dateOptions: any,
    selectedMonthIds: number[],
    filterFunction: (nums: number[]) => unknown
  ): Date {
    let rawDate = _.find(dateOptions, (dateOption) => {
      return dateOption.id === filterFunction(selectedMonthIds);
    });
    if (!rawDate || !rawDate.date) {
      const filteredDateId = filterFunction(dateOptions.map((x) => x.id));
      rawDate = dateOptions.find((x) => x.id === filteredDateId);
    }
    const dateResult = FilterProfile.monthId2Date(rawDate.id);
    rawDate.date = dateResult;
    return dateResult;
  }

  private createSearchParams = (filter: FilterValues): SearchQuery => {
    const filterBody = new SearchQuery();

    filterBody.MonthIDStart = filter.monthFromId;
    filterBody.MonthIDEnd = filter.monthToId;

    if (filter) {
      if (filter.regions) {
        filterBody.RegionList = filter.regions.filter((f) => !!f).slice();
      }
      if (filter.districts) {
        filterBody.DistrictList = filter.districts.filter((f) => !!f).slice();
      }
      if (filter.branches) {
        filterBody.BranchList = filter.branches.filter((f) => !!f).slice();
      }
      if (filter.rouseMarkets) {
        filterBody.RouseMarketList = filter.rouseMarkets
          .filter((f) => !!f)
          .slice();
      }
      if (filter.rouseCategories) {
        filterBody.RouseCategoryList = filter.rouseCategories
          .filter((f) => !!f)
          .slice();
      }
      if (filter.rouseProductTypes) {
        filterBody.RouseProductTypeList = filter.rouseProductTypes
          .filter((f) => !!f)
          .slice();
      }
      if (filter.clientProductTypes) {
        filterBody.ClientProductTypeList = filter.clientProductTypes
          .filter((f) => !!f)
          .slice();
      }
      if (filter.clientCategories) {
        filterBody.ClientCategoryList = filter.clientCategories
          .filter((f) => !!f)
          .slice();
      }
      if (filter.catProductGroups) {
        filterBody.CatProductGroupsList = filter.catProductGroups
          .filter((f) => !!f)
          .slice();
      }
      if (filter.customerSizes) {
        filterBody.CustomerSizeList = filter.customerSizes
          .filter((f) => !!f)
          .slice();
      }
      if (filter.outlierReasons) {
        filterBody.OutlierReasonList = filter.outlierReasons.slice();
      }
      if (filter.verticals) {
        filterBody.ClientVerticalList = filter.verticals.slice();
      }
      if (filter.useRentedAsProductTypes) {
        // the filter key is filter.transactions.use-rented-as-product-types and is binary.
        // but the query needs to be singular and needs to be a boolean
        filterBody.UseRentedAsProductType =
          filter.useRentedAsProductTypes === 1 ? true : false;
      }
    }

    filterBody.ExcludeContracts = filter.excludeContracts;
    filterBody.ExcludeNationalAccts = filter.excludeNationalAccts;
    filterBody.ExcludeRpos = filter.excludeRpos;
    filterBody.ExcludeSpecialPricing = filter.excludeSpecialPricing;
    filterBody.ExcludeSubstitutions = filter.excludeSubstitutions;
    filterBody.ExcludePrimeUnits = filter.excludePrimeUnits;
    filterBody.ExcludeReRent = filter.excludeReRent;
    filterBody.ExcludeOperated = filter.excludeOperated;

    filterBody.UseRouseSchema = filter.useRouseSchema;
    filterBody.IncludeAllRateTransactions = filter.includeAllRateTransactions;
    filterBody.CycleBillRange = filter.cycleBillRange.slice();

    filterBody.Bid = filter.bid;
    filterBody.Cid = filter.cid;
    filterBody.Gid = filter.gid;

    if (this.clientId) {
      filterBody.ClientId = this.clientId;
      this.filter.ClientId = this.clientId;
    }

    return filterBody;
  };

  private createSearchParamsForGet = (filter: FilterValues): QueryParams => {
    const params = new QueryParams();

    params.set('MonthIDStart', filter.monthFromId.toString());
    params.set('MonthIDEnd', filter.monthToId.toString());

    if (filter.regions && !!filter.regions.length) {
      filter.regions
        .filter((f) => !!f)
        .map((region) =>
          params.append('RegionList', decodeURIComponent(region.toString()))
        );
    }

    if (filter.districts && !!filter.districts.length) {
      filter.districts
        .filter((f) => !!f)
        .map((district) => params.append('DistrictList', district.toString()));
    }

    if (filter.branches && !!filter.branches.length) {
      filter.branches
        .filter((f) => !!f)
        .map((branch) => params.append('BranchList', branch.toString()));
    }

    if (filter.excludeContracts !== null) {
      params.set('excludeContracts', filter.excludeContracts.toString());
    }

    if (filter.excludeNationalAccts !== null) {
      params.set(
        'excludeNationalAccts',
        filter.excludeNationalAccts.toString()
      );
    }

    if (filter.excludeRpos !== null) {
      params.set('excludeRpos', filter.excludeRpos.toString());
    }

    if (filter.excludeSpecialPricing !== null) {
      params.set(
        'excludeSpecialPricing',
        filter.excludeSpecialPricing.toString()
      );
    }

    if (filter.excludeSubstitutions !== null) {
      params.set(
        'excludeSubstitutions',
        filter.excludeSubstitutions.toString()
      );
    }

    if (filter.excludePrimeUnits !== null) {
      params.set('excludePrimeUnits', filter.excludePrimeUnits.toString());
    }

    if (filter.excludeOperated !== null) {
      params.set('excludeOperated', filter.excludeOperated.toString());
    }

    if (filter.useRouseSchema) {
      params.set('UseRouseSchema', filter.useRouseSchema.toString());
    }

    if (filter.includeAllRateTransactions) {
      params.set(
        'IncludeAllRateTransactions',
        filter.includeAllRateTransactions.toString()
      );
    }

    if (filter.cycleBillRange && filter.cycleBillRange.length) {
      _.each(filter.cycleBillRange, (x) => {
        params.append('CycleBillRange', x.toString());
      });
    }

    if (filter.excludePrimeUnits != null) {
      params.set('excludePrimeUnits', filter.excludePrimeUnits.toString());
    }

    if (filter.excludeReRent != null) {
      params.set('excludeReRent', filter.excludeReRent.toString());
    }

    if (filter.bid) {
      params.set('Bid', filter.bid.toString());
    }

    if (filter.cid) {
      params.set('Cid', filter.cid.toString());
    }

    if (filter.gid) {
      params.set('Gid', filter.gid.toString());
    }

    if (this.clientId) {
      params.set('ClientId', this.clientId);
    }

    if (filter.rouseCategories && !!filter.rouseCategories.length) {
      filter.rouseCategories
        .filter((f) => !!f)
        .map((rouseCat) =>
          params.append('RouseCategoryList', rouseCat.toString())
        );
    }

    if (filter.clientCategories && !!filter.clientCategories.length) {
      filter.clientCategories
        .filter((f) => !!f)
        .map((clientCat) =>
          params.append('ClientCategoryList', clientCat.toString())
        );
    }

    if (filter.catProductGroups && !!filter.catProductGroups.length) {
      filter.catProductGroups
        .filter((f) => !!f)
        .map((catProducGroup) =>
          params.append('CatProductGroupsList', catProducGroup)
        );
    }

    if (filter.rouseProductTypes && !!filter.rouseProductTypes.length) {
      filter.rouseProductTypes
        .filter((f) => !!f)
        .map((rouseProductType) =>
          params.append('RouseProductTypeList', rouseProductType.toString())
        );
    }
    if (filter.clientProductTypes && !!filter.clientProductTypes.length) {
      filter.clientProductTypes
        .filter((f) => !!f)
        .map((clientProductType) =>
          params.append('ClientProductTypeList', clientProductType.toString())
        );
    }

    if (filter.rouseMarkets && !!filter.rouseMarkets.length) {
      filter.rouseMarkets
        .filter((f) => !!f)
        .map((rouseMarket) =>
          params.append('RouseMarketList', rouseMarket.toString())
        );
    }

    if (filter.customerSizes && !!filter.customerSizes.length) {
      filter.customerSizes
        .filter((f) => !!f)
        .map((x) => params.append('CustomerSizeList', x.toString()));
    }

    if (filter.outlierReasons && !!filter.outlierReasons.length) {
      filter.outlierReasons.map((x) =>
        params.append('OutlierReasonList', x.toString())
      );
    }

    if (filter.verticals && !!filter.verticals.length) {
      filter.verticals.map((x) =>
        params.append('ClientVerticalList', x.toString())
      );
    }

    if (typeof filter.useRentedAsProductTypes !== undefined) {
      const value = filter.useRentedAsProductTypes === 1 ? true : false;
      params.append('UseRentedAsProductType', value);
    }

    return params;
  };

  private convertToParam(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-1`;
  }

  private copy<T>(object: T): T {
    const objectCopy = <T>{};
    for (const key in object) {
      // eslint-disable-next-line
      if (object.hasOwnProperty(key)) {
        objectCopy[key] = object[key];
      }
    }
    return objectCopy;
  }

  public getEquipmentPostFilters(searchQuery): SearchQuery {
    const routeUseRentedAsProductType: any =
      this.routeParamMap.useRentedAsProductType;

    if (routeUseRentedAsProductType) {
      searchQuery.UseRentedAsProductType =
        routeUseRentedAsProductType === 'true' ? true : false;
    }
    return searchQuery;
  }
}

export class FilterDateRange {
  from: Date;
  to: Date;
}
