﻿<rdo-overlay [enabled]="loading"></rdo-overlay>
<rdo-breadcrumb></rdo-breadcrumb>
<div class="container-fluid" id="rdo-grid">
	<div class="row">
		<div class="col-xs-12">
			<rdo-grid-table-ng
                #grid
                *ngIf="customers"
                [name]="gridName"
                [gridConfig]="gridConfig"
				[pagedData]="customers"
                [sorting]="sorting"
                [scrollScale]="'largeScale'"
                [(paging)]="paging"
				(pageOnChange)="changePage($event)"
                (lazyLoadOnChange)="changeLazyLoad($event)"
				[totalCount]="totalCount"
                [frozenWidth]="frozenWidth"
                [showColumnSelector]="true"
				(visibilityChanged)="visibilityChanged($event)"
                (downloadExcelClick)="exportExcel($event)"
            />
		</div>
	</div>
</div>
