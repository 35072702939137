import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  OnDestroy,
} from '@angular/core';
import { FilterProfileService } from '../filter-profile.service';
import * as Sentry from '@sentry/angular';
import { Subscription } from 'rxjs';
import { ErrorService } from '@/app/core';

@Component({
  selector: 'rdo-save-profile-as-popup',
  templateUrl: './save-profile-as-popup.component.html',
  styleUrls: ['./save-profile-as-popup.component.scss'],
})
export class SaveProfileAsPopupComponent implements OnInit, OnDestroy {
  public profileName: string;
  public isDefault: boolean = false;
  public showPopup: boolean = false;
  @Input() rawFilterProfileData;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();
  private profileNameIsValid: boolean = true;
  private subscription: Subscription;
  constructor(
    private filterProfileService: FilterProfileService,
    private errorService: ErrorService
  ) {
    this.filterProfileService.showSaveAs.subscribe((value) => {
      this.showPopup = value;
    });
  }

  ngOnInit(): void {
    this.subscription = this.filterProfileService.currentProfile.subscribe(
      (currentProfile) => {
        if (this.showPopup) {
          this.filterProfileService.showToast('create', true);
          this.onClose();
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public isProfileNameIsValid() {
    return this.profileNameIsValid;
  }

  public onSave() {
    try {
      this.profileNameIsValid = this.filterProfileService.profileNameIsValid(
        this.profileName
      );
      if (this.profileNameIsValid) {
        let profileData = this.filterProfileService.readFilterComponentValues(); // Use this rather than applying stuff
        profileData = profileData ? profileData : this.rawFilterProfileData;
        const profile = this.filterProfileService.buildFilterProfile(
          this.profileName,
          this.isDefault,
          profileData
        );
        this.filterProfileService.saveNewProfile(profile);
      }
    } catch (error) {
      this.filterProfileService.toggleSpinner(false);
      this.filterProfileService.showToast('create', false);
      this.errorService.logSentryError(
        error,
        'SaveProfileAsPopupComponent.onSave failed'
      );
    }
  }

  public onClose() {
    this.close.emit();
    this.showPopup = false;
    this.filterProfileService.showSaveAs.next(false);
    this.profileName = undefined;
    this.profileNameIsValid = true;
    this.isDefault = false;
  }
}
