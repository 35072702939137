/* This component has following problems that need to be address later:
 *  1. Child links are not updated on nav card change. This is due ng child router bug. https://github.com/angular/angular/issues/5405
 *  2. Navigating to this screen from outside the app (via direct url) removes url params. This needs further investigation.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { map, mergeMap, filter } from 'rxjs/operators';
import { zip } from 'rxjs/observable/zip';
import * as _ from 'lodash';
import { EquipmentService } from './../equipment.service';
import type { GetCardsOptions } from './../equipment.service';
import { SelectedProductTypeService } from './selected-product-type.service';
import {
  ProductTypeBaseComponent,
  ProductTypesRouteParams,
} from './product-type-base.component';
import {
  ActiveFilterService,
  HighchartsConfiguration,
  ConfigService,
  ViewService,
  ComparisonMode,
  ClientProfileService,
} from './../../core';
import { HeaderService } from './../../header';
import { ProductTypeCard, TxAttributes } from './../../models';
import { FilterProfileService } from '../../filter/profiles/filter-profile.service';
import { CODENAMES } from '../../core/constants';
import { AuthenticationService } from '../../core/authentication/authentication.service';

@Component({
  selector: 'rdo-product-types',
  templateUrl: './product-types.component.html',
  styleUrls: ['./../equipment.component.scss'],
})
export class ProductTypesComponent
  extends ProductTypeBaseComponent
  implements OnInit, OnDestroy
{
  allProductCards: Array<ProductTypeCard>;
  productCards: Array<ProductTypeCard>;
  selectedProduct: ProductTypeCard;
  chartConfig: HighchartsConfiguration;
  hasAssetGrid = false;
  private currentChildRoute = '';
  private mode: ComparisonMode = ComparisonMode.Benchmark;
  private currentCardPage = 1;
  private cardPageSize = 20;
  private clientAttributes = new TxAttributes(<TxAttributes>{ IsTrial: true });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    filterService: ActiveFilterService,
    private equipmentService: EquipmentService,
    selectedProductTypeService: SelectedProductTypeService,
    private configService: ConfigService,
    private viewService: ViewService,
    private headerService: HeaderService,
    protected clientProfileService: ClientProfileService,
    protected filterProfileService: FilterProfileService,
    protected authenticationService: AuthenticationService
  ) {
    super(selectedProductTypeService, null, filterService);
  }

  ngOnInit() {
    this.authenticationService.startSentryTransaction(
      this.router.routerState.snapshot.url
    );
    this.subscriptions.push(
      zip(
        this.filterProfileService.wait4CurrentProfile(),
        this.clientProfileService.getClientAttributes()
      ).subscribe((results: [boolean, TxAttributes]) => {
        this.hasAssetGrid =
          !!this.filterProfileService.readCurrentProfileProperty(
            CODENAMES.CN_HAS_ASSET_GRID
          );
        this.clientAttributes = results[1];
      })
    );

    if (this.params == null) {
      this.subscriptions.push(
        this.route.params.subscribe((params) => {
          const routeParams = this.getRouteParams(params);

          if (this.reloadCards(this.params, routeParams)) {
            this.load(routeParams, true);
          } else if (this.hasProductTypeChanged(this.params, routeParams)) {
            this.load(routeParams);
          }
        })
      );
    }

    this.subscriptions.push(
      this.filterService.filterChange.subscribe(() =>
        this.load(this.params, true)
      )
    );

    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.route),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)
        )
        .subscribe((event) => {
          if (event.name) {
            // event.name === 'dashboard'
            // 	? this.currentChildRoute = ''
            // 	: this.currentChildRoute = event.name;
            this.currentChildRoute =
              event.name === 'dashboard' ? '' : event.name;
          }
        })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.selectedProductTypeService.notify(null);
  }

  load = (params: ProductTypesRouteParams, reloadCards: boolean = false) => {
    if (params !== undefined) {
      if (!this.allProductCards || reloadCards) {
        const cardsOptions: GetCardsOptions = {
          useRentedAsProductType: params.useRentedAsProductType,
        };
        zip(
          this.equipmentService
            .getProductTypeCards(
              params.category,
              params.sortOptions,
              cardsOptions
            )
            .pipe(map((d) => <ProductTypeCard[]>d)),
          this.viewService.getComparisonMode()
        ).subscribe((results: [ProductTypeCard[], ComparisonMode]) => {
          this.allProductCards = results[0];
          this.mode = results[1];
          this.params = params;

          const index = this.getProductIndexFromCards(params);
          this.selectedProduct = this.allProductCards[index];

          if (!this.selectedProduct) {
            this.router.navigate(['/equipment']);
            return;
          }

          if (this.allProductCards.length <= this.cardPageSize) {
            this.productCards = this.allProductCards;
          } else {
            this.currentCardPage =
              index <= this.cardPageSize
                ? this.currentCardPage
                : index / this.cardPageSize;
            const previousTwenty = _.slice(
              this.allProductCards,
              index <= this.cardPageSize ? 0 : index - this.cardPageSize,
              index
            );
            this.productCards = _.union(
              previousTwenty,
              _.slice(this.allProductCards, index, index + this.cardPageSize)
            );
          }

          this.selectedProductTypeService.notify(this.selectedProduct);
          this.authenticationService.finishSentryTransaction();
        });
      } else {
        const index = this.getProductIndexFromCards(params);
        this.selectedProduct = this.allProductCards[index];

        this.selectedProductTypeService.notify(this.selectedProduct);
        this.params = params;
        this.authenticationService.finishSentryTransaction();
      }
    }
  };

  private getProductIndexFromCards(params: ProductTypesRouteParams): number {
    return _.findIndex(this.allProductCards, (card: ProductTypeCard) => {
      const result =
        card.ProductType &&
        params.productType &&
        // We have to look at the Description property when UseRentedAsProductType is true because the productionType name usually changes
        // while the Description doesn't. This feels like a hack.  We could probably always compare the product type and the Description as well.
        (card.ProductType.toLowerCase().trim() ===
          params.productType.toLowerCase().trim() ||
          card.Description.toLowerCase().trim() ===
            params.productType.toLowerCase().trim());
      return result;
    });
  }

  selectProduct = (selected: any) => {
    this.selectedProduct = selected;

    const buildTree = (childRoute, urlParameters): any[] => {
      const productType: string = urlParameters.useRentedAsProductType
        ? selected.Description
        : selected.ProductType;

      const params = urlParameters.clone();

      // TODO: All of this repurposing of properties seems like a bad idea.
      if (params.useRentedAsProductType) {
        params.rentedAsCatClass = selected.ProductType;
      }

      const result = childRoute
        ? ['../', productType, params, childRoute]
        : ['../', productType, params];

      if (params.useRentedAsProductType && !childRoute) {
        result.push('rate-types');
      }

      return result;
    };

    const tree = buildTree(this.currentChildRoute, this.params);

    const urlTree = this.router.createUrlTree(tree, {
      relativeTo: this.route.parent,
    });

    this.router.navigateByUrl(urlTree);
    this.selectedProductTypeService.notify(selected);
  };

  isSelected = (product: any): boolean => {
    return (
      !this.selectedProduct ||
      this.selectedProduct.ProductType === product.ProductType
    );
  };

  getPaginatedCards = () => {
    this.currentCardPage++;
    const offset = (this.currentCardPage - 1) * this.cardPageSize;
    const nextCards = _.drop(this.allProductCards, offset).slice(
      0,
      this.cardPageSize
    );

    if (nextCards && nextCards.length) {
      this.productCards = _.union(this.productCards, nextCards);
    }
  };
}
