import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  signal,
  WritableSignal,
} from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import {
  ActiveFilterService,
  ColumnDefinitionService,
  MetricsGridConfig,
  BreadcrumbService,
  IGridColumnGroup,
  GridConfigService,
  ClientProfileService,
  GridTableNgComponent,
  FormatService,
  GridSortingService,
} from '../core';
import { SalesRepService } from './sales-rep.service';
import { DownloadsService } from '../downloads';
import { HeaderService } from '../header';
import { PageOptionsNg, SortOptionsNg, SortOptions } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { Observable } from 'rxjs';
import { SalesRepCustomerService } from './sales-rep-customer.service';
import { map } from 'rxjs/operators';
import { QueryParams } from '../core/http/query-params';
import { Router } from '@angular/router';
import { FilterProfileUtilsService } from '@/app/filter/profiles/filter-profile-utils.service';

@Component({
  templateUrl: 'sales-reps.component.html',
  styleUrls: ['sales-rep.component.scss'],
})
export class SalesRepsComponent implements OnInit, OnDestroy {
  loading = true;
  salesReps: any;
  gridConfig: MetricsGridConfig;
  frozenRows: any;
  paging = new PageOptionsNg();
  sorting: SortOptionsNg;
  totalCount = 0;
  frozenWidth: string;
  gridName = 'SALESREPS_GRID';
  calculatedProductTypeLength = 175;
  private subscriptions: Array<Subscription> = [];
  salesRepsRequestInProgress: Subscription;
  @ViewChild('grid') grid: GridTableNgComponent;
  constructor(
    private authenticationService: AuthenticationService,
    private filterService: ActiveFilterService,
    private salesRepsService: SalesRepService,
    private columnService: ColumnDefinitionService,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private downloadsService: DownloadsService,
    private formatService: FormatService,
    private customerService: SalesRepCustomerService,
    private gridConfigService: GridConfigService,
    private clientProfileService: ClientProfileService,
    private gridSortingService: GridSortingService,
    private router: Router,
    private filterProfileUtilsService: FilterProfileUtilsService
  ) {}

  ngOnInit() {
    this.authenticationService.startSentryTransaction(
      this.router.routerState.snapshot.url
    );
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptionsNg();

    this.loading = true;
    this.breadcrumbService.update([
      { title: 'main.core.common.counts.sales_reps.all', class: 'active' },
    ]);

    this.clientProfileService.getClientAttributes().subscribe((attributes) => {
      this.calculatedProductTypeLength = attributes.MaxProdTypeLen * 7.25;
      this.calculatedProductTypeLength =
        this.calculatedProductTypeLength < 175
          ? 175
          : this.calculatedProductTypeLength;
      this.configureGrid(true);
      this.load(true);
    });

    this.subscriptions.push(
      this.filterService.filterChange.subscribe(() => {
        this.paging.page = 1;
        this.grid.resetPaginator();
        this.configureGrid(true);
        this.load(true);
      })
    );

    this.subscriptions.push(
      this.authenticationService.userInfoView.subscribe((userInfo) => {
        if (this.gridConfig) {
          this.gridConfig.enableExcelExport =
            userInfo.HasClientAccessToExportData;
        }
      })
    );
  }

  ngOnDestroy() {
    _.each(this.subscriptions, (s) => s.unsubscribe());
  }

  load = (initEvent?: boolean) => {
    if (this.salesRepsRequestInProgress) {
      this.salesRepsRequestInProgress.unsubscribe();
    }
    this.loading = true;
    const params = new QueryParams();

    if (
      this.gridConfig.groups[1].visible ||
      (initEvent && this.isStoredValueVisible(1))
    ) {
      params.set('groupByCustomer', 'true');
    }
    if (
      this.gridConfig.groups[2].visible ||
      (initEvent && this.isStoredValueVisible(2))
    ) {
      params.set('groupByProductType', 'true');
    }
    if (this.filterService.getCurrentFilter().useRouseSchema) {
      params.set('useRouseSchema', 'true');
    }

    this.salesRepsRequestInProgress = this.salesRepsService
      .getSalesReps(this.paging, this.getSorting(), params)
      .subscribe((x) => {
        this.loading = false;

        this.totalCount = x.TotalCount;
        if (x.Items && x.Items.length > 0) {
          this.salesReps = x.Items;
        } else {
          this.salesReps = [];
        }
        this.authenticationService.finishSentryTransaction();
      });
  };

  isStoredValueVisible(index: number): boolean {
    return (
      this.gridConfigService.getConfig(this.gridName) &&
      this.gridConfigService.getConfig(this.gridName)[index] &&
      this.gridConfigService.getConfig(this.gridName)[index].visible
    );
  }

  changePage = (event: any) => {
    if (event.rows !== this.paging.pageSize) {
      this.paging.pageSize = event.rows;
    }

    this.paging.page = event.first < 1 ? 1 : event.first / event.rows + 1;
    this.load();
  };

  changeLazyLoad = (event: LazyLoadEvent) => {
    if (
      this.sorting.sortField === event.sortField &&
      this.sorting.sortOrder === event.sortOrder
    ) {
      return;
    }

    this.gridSortingService.setSortOption(
      event.sortField,
      event.sortOrder === -1
    );

    this.sorting.sortField =
      event.sortField || this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      event.sortOrder || this.gridSortingService.getSortDirectionOrDefault();

    this.load();
  };

  visibilityChanged = (event: IGridColumnGroup) => {
    const aux = event;
    const countHeader = 'main.core.common.counts.customers.count';
    const productTypeHeader = this.filterProfileUtilsService
      .getProductTypesHeader()
      .ambiguousCount();
    const reloadColumns = [productTypeHeader, countHeader];
    if (event.columnSelectorTitle === countHeader) {
      this.gridConfig.groups[3].visible = !event.visible;
    }
    this.gridConfig.groups[0].columns[0].isGroupingEnabled =
      this.gridConfig.groups[1].visible || this.gridConfig.groups[2].visible;

    if (reloadColumns.includes(event.columnSelectorTitle)) {
      this.paging.page = 1;
      this.grid.resetPaginator();
      this.load();
    }
  };

  exportExcel = (args: any) => {
    this.loading = true;
    const translatedConfig = this.gridConfig.cloneAndTranslate((text) =>
      this.formatService.translateAndFormat(text, false)
    );
    this.subscriptions.push(
      this.salesRepsService
        .getSalesRepsDownload(this.getSorting(), translatedConfig)
        .subscribe((blob) => {
          this.loading = false;
          this.downloadsService.saveExcelBlob(blob);
        })
    );
  };

  private configureGrid = (initEvent?: boolean) => {
    const salesRepNameWidth = 225;
    const customerCountColumn = this.columnService.CustomerCount();
    if (
      initEvent &&
      this.gridSortingService.getSortFieldOrDefault() === 'CustomerCount'
    ) {
      customerCountColumn.width += 15;
    }
    const transactionCountColumn = this.columnService.TransactionCount();
    if (
      initEvent &&
      this.gridSortingService.getSortFieldOrDefault() === 'TransactionCount'
    ) {
      transactionCountColumn.width += 15;
    }
    this.frozenWidth = `${salesRepNameWidth + (customerCountColumn.width || 110) + (transactionCountColumn.width || 110)}px`;

    this.gridConfig = new MetricsGridConfig(
      [
        {
          title: '',
          visible: true,
          locked: true,
          columnSelectorTitle: 'main.core.common.counts.sales_reps.singular',
          columns: [
            {
              title: 'main.core.common.counts.sales_reps.singular',
              field: 'SalesRepName',
              sortColumn: 'SalesRepName',
              width: 100,
              autoWidth: true,
              maxWidth: 255,
              headerStyle: {
                'text-align': 'left',
              },
              cellStyle: {
                'text-align': 'left',
              },
              linkDsl: (v, r) => {
                const sorting = this.getSorting();
                const params = {
                  sortOn: sorting.sortOn,
                  desc: sorting.descending,
                };
                return ['.', r.SalesRepId, params];
              },
            },
          ],
        },
        {
          title: '',
          visible: false,
          locked: true,
          forceCanClose: true,
          keyColumn: true,
          columnSelectorTitle: 'main.core.common.counts.customers.count',
          columns: [
            {
              title: 'main.core.common.counts.customers.count',
              field: 'CustomerName',
              sortColumn: 'CustomerName',
              headerStyle: {
                'text-align': 'left',
              },
              cellStyle: {
                'text-align': 'left',
              },
              width: 100,
              maxWidth: 180,
              autoWidth: true,
              isString: true,
            },
          ],
        },
        {
          title: '',
          visible: false,
          locked: true,
          forceCanClose: true,
          keyColumn: true,
          columnSelectorTitle: this.filterProfileUtilsService
            .getProductTypesHeader()
            .ambiguousCount(), //'main.core.common.counts.product_types.count',
          columns: [
            {
              title: this.filterProfileUtilsService
                .getProductTypesHeader()
                .ambiguousCount(), //'main.core.common.counts.product_types.count',
              field: 'ProductTypeDescription',
              sortColumn: 'ProductTypeDescription',
              headerStyle: {
                'text-align': 'left',
              },
              cellStyle: {
                'text-align': 'left',
              },
              width: 100,
              maxWidth: 500,
              minWidth: 115,
              autoWidth: true,
            },
          ],
        },
        {
          title: '',
          visible: true,
          locked: true,
          columnSelectorTitle: 'main.core.common.counts.customers.count',
          columns: [customerCountColumn],
        },
        {
          title: '',
          visible: true,
          locked: true,
          columnSelectorTitle: 'main.core.common.counts.transactions.count',
          columns: [transactionCountColumn],
        },
        ...this.columnService.StandardMetricSetColumnGroups(true),
      ],
      (p) => this.loadChildren(p),
      true
    );
  };
  private loadChildren = (parent: any): Observable<Array<any>> => {
    const sorting = this.getSorting();

    return this.customerService
      .getSalesRepCustomers(parent.SalesRepId, this.paging, sorting)
      .pipe(
        map((result) => {
          return result.Items.map(function (item) {
            const clone = (<any>Object).assign({}, item);
            clone.SalesRepId = parent.SalesRepId;
            clone.SalesRepName = null;
            clone.CustomerCount = item.CustomerName;
            return clone;
          });
        })
      );
    // return this.customerService.getSalesRepCustomersProductTypes(parent.SalesRepId, parent.CustomerId, sorting)
    // 	.pipe(map(items => {
    // 		return items.map(this.convert)
    // 	}));
  };

  private convert = (salesRepId: any, item: any): any => {
    const clone = (<any>Object).assign({}, item);
    clone.CustomerName = item.CustomerName;
    clone.ProductTypeCount = item.ProductTypeCount;
    return clone;
  };

  private getSorting = (): SortOptions => {
    this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      this.gridSortingService.getSortDirectionOrDefault();
    return new SortOptions(
      this.sorting.sortField,
      !(this.sorting.sortOrder === 1)
    );
  };
}
