import { Injectable, signal, WritableSignal } from '@angular/core';
import { FilterProfileService } from '@/app/filter/profiles/filter-profile.service';
import { FilterProfileMap } from '@/app/filter/profiles/models';
import { FilterProfile } from '@/app/filter/profiles/models';
import { CODENAMES as filterKeys } from '@/app/core/constants';
import { ActiveFilterService } from '@/app/core';
import { FilterValues } from '@/app/models';

export const PRODUCT_COLUMN_TRANSLATION_KEYS = {
  RENTED_AS: {
    SINGULAR: 'main.core.common.counts.rented_as_product_types.singular',
    PLURAL: 'main.core.common.counts.rented_as_product_types.plural',
    AMBIGUOUS: 'main.core.common.counts.rented_as_product_types.count',
  },
  PRODUCT: {
    SINGULAR: 'main.core.common.counts.product_types.singular',
    PLURAL: 'main.core.common.counts.product_types.plural',
    AMBIGUOUS: 'main.core.common.counts.product_types.count',
  },
};

@Injectable({
  providedIn: 'root',
})
export class FilterProfileUtilsService {
  public hasRentedAsData: WritableSignal<boolean> = signal(false);
  public useRentedAsProductTypesValue: WritableSignal<number> = signal(0);
  public useRentedAsProductTypesColumn: WritableSignal<string> = signal('');

  constructor(
    private profile: FilterProfileService,
    private activeFilterService: ActiveFilterService
  ) {
    activeFilterService.filterChange.subscribe((filters: FilterValues) => {
      const value = filters.useRentedAsProductTypes || 0;
      this.useRentedAsProductTypesValue.set(value);
    });

    profile.currentProfile.subscribe((profile: FilterProfile) => {
      if (FilterProfileMap.validateProfile(profile)) {
        this.hasRentedAsData.set(this.getBoolean(filterKeys.CN_SHOW_RENTED_AS));
        const primaryProductType = this.getAttributeValue(
          filterKeys.CN_TRANSACTION_USE_RENTED_AS_PRODUCT_TYPE
        );
        this.useRentedAsProductTypesValue.set(primaryProductType);
      }
    });
  }

  private getBoolean(key: string, src?: FilterValues): boolean {
    const value = src ? src[key] : this.getAttributeValue(key);
    return value === 1 || value === '1' || value === 'true' ? true : false;
  }

  private getAttributeValue(key: string): any {
    return this.profile.readCurrentProfileProperty(key);
  }

  /**
   * Get column header depending on the client configuration
   * https://rouseservices.atlassian.net/browse/ANA-21380
   */
  public getProductTypesHeader(): {
    singular: () => string;
    plural: () => string;
    ambiguousCount: () => string;
  } {
    if (this.hasRentedAsData() && this.useRentedAsProductTypesValue() === 1) {
      return {
        singular: () => {
          return PRODUCT_COLUMN_TRANSLATION_KEYS.RENTED_AS.SINGULAR;
        },
        plural: (): string => {
          return PRODUCT_COLUMN_TRANSLATION_KEYS.RENTED_AS.PLURAL;
        },
        ambiguousCount: (): string => {
          return PRODUCT_COLUMN_TRANSLATION_KEYS.RENTED_AS.AMBIGUOUS;
        },
      };
    } else {
      return {
        singular: () => {
          return PRODUCT_COLUMN_TRANSLATION_KEYS.PRODUCT.SINGULAR;
        },
        plural: () => {
          return PRODUCT_COLUMN_TRANSLATION_KEYS.PRODUCT.PLURAL;
        },
        ambiguousCount: () => {
          return PRODUCT_COLUMN_TRANSLATION_KEYS.PRODUCT.AMBIGUOUS;
        },
      };
    }
  }
}
