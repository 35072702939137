import {
  Injectable,
  signal,
  effect,
  WritableSignal,
  Signal,
  computed,
  Inject,
} from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { MetricsHttpService } from '@/app/core/http/metrics-http.service';
import * as Sentry from '@sentry/angular';
import { DOCUMENT } from '@angular/common';
import random from 'lodash/random';
import { Subscription } from 'rxjs/Subscription';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../errors';

export const LOGO_SIZING_CONFIG = '0x38xc';

export const CSS_NAME_CACHE = new Map();

/**
 * Create a css variable name with kebab case and prefix.
 * @param str
 * @param prefix
 */
export function getCssVarName(
  str: string,
  prefix: string = 'rdo-branding'
): string {
  if (CSS_NAME_CACHE.has(str)) {
    return CSS_NAME_CACHE.get(str);
  }
  const result = `--${prefix}-${str.split('_').join('-')}`;
  CSS_NAME_CACHE.set(str, result);
  return result;
}

/**
 * Get the css var string with a default color in case the variable doesn't exist.
 * @param key
 * @param defaultColor
 */
export function getClientBrandingCssVarString(
  key: 'primary_chart_color' | 'secondary_chart_color',
  defaultColor: HexString
): string {
  return `var(${getCssVarName(key)}, ${defaultColor})`;
}

/**
 * Add the size string to the logo url returned from api.
 * @param url
 * @param sizeStr
 */
export function updateLogoSize(
  url: string | null,
  sizeStr = LOGO_SIZING_CONFIG
): string | null {
  if (!url) {
    return null;
  }
  const parts = url.split('.');
  const fileExt = parts.pop();
  const removedSize = parts.pop();
  return `${parts.join('.')}.${sizeStr}.${fileExt}`;
}

const MOCK_PRIMARY = '#FFC901';
const MOCK_SECONDARY = '#faedbb';

const MOCK_DATA = JSON.parse(`{
  "profile_id": "1234",
  "date_created": "2024-09-13T17:02:11.261286Z",
  "date_modified": "2024-09-13T17:02:11.261286Z",
  "created_by": "test.user@xyz.net",
  "modified_by": "test.user@xyz.net",
  "data": {
    "files": [
      {
        "url": "https://media.stage.rouseservices.com/xyz/Tex-Logo-c6b0df22d7f2.0x48xc.png",
        "name": "logo.webp",
        "size": 45000,
        "type": "logo"
      }
    ],
    "dashboard_background_color": "${MOCK_PRIMARY}",
    "dashboard_font_color": "#000000",
    "primary_chart_color": "${MOCK_PRIMARY}",
    "secondary_chart_color": "${MOCK_SECONDARY}",
    "mobile_background_color": "#D11C1C",
    "mobile_font_color": "#823030"
  }
}`);

const DEBUG = false;

@Injectable({
  providedIn: 'root',
})
export class ClientBrandingService {
  static URL = 'customer-profiles/customer-branding';
  private currentClientId: WritableSignal<number | null> = signal(null);
  public data: WritableSignal<TODO | null> = signal(null);
  public isLoaded: WritableSignal<boolean> = signal(false);
  public isLoading: WritableSignal<boolean> = signal(false);
  public isReadySubject: Subject<boolean>;

  constructor(
    private authenticationService: AuthenticationService,
    private metricsHttp: MetricsHttpService,
    private errorService: ErrorService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.load();
  }

  public load(): Subject<boolean> {
    if (this.isLoaded() || this.isLoading()) {
      return this.isReadySubject;
    }

    this.isReadySubject = new Subject();
    this.isLoaded.set(false);
    this.isLoading.set(true);
    this.authenticationService.selectedClientId.subscribe((clientId) => {
      if (clientId && clientId !== this.currentClientId()) {
        this.currentClientId.set(clientId);
        if (DEBUG) {
          this.mockLoadInternal();
        } else {
          this.loadInternal();
        }
      }
    });
    return this.isReadySubject;
  }

  private mockLoadInternal = async () => {
    let mock = MOCK_DATA;
    const rand = random(0, 4);
    console.log('client-branding.service.mockLoadInternal  ', rand);
    let url;
    switch (rand) {
      case 0:
        url =
          'https://media.stage.rouseservices.com/xyz/Tex-Logo-c6b0df22d7f2.png';
        break;
      case 1:
        url =
          'https://media.stage.rouseservices.com/xyz/erg_logo-29ad0de096df.png';
        break;
      case 2:
        url =
          'https://media.stage.rouseservices.com/xyz/United_Rentals_Logo.svg-2f195626fbd1.png';
        break;
      default:
        mock = {};
        break;
    }
    if (url) {
      mock.data.files[0].url = url;
    }

    this.data.set(mock?.data || {});
    const logoUrl = this.logoUrl();
    console.log(
      'client-branding.service.mockLoadInternal  ',
      this.data(),
      logoUrl
    );
    this.setCSSVars(mock.data);
    await this.preloadLogo();
    this.isLoaded.set(true);
    this.isLoading.set(false);
    this.isReadySubject.next(true);
    this.isReadySubject.complete();
  };

  private loadInternal = (): void => {
    this.metricsHttp.get(ClientBrandingService.URL).subscribe(
      async (resp) => {
        try {
          this.data.set(resp?.data);
          this.setCSSVars(resp?.data);
          await this.preloadLogo();
        } catch (error) {
          this.handleError(
            error,
            'client-branding.service.loadInternal failed'
          );
        } finally {
          this.isLoaded.set(true);
          this.isLoading.set(false);
          this.isReadySubject.next(true);
          this.isReadySubject.complete();
        }
      },
      (error: HttpErrorResponse) => {
        this.isLoaded.set(true);
        this.isLoading.set(false);
        this.handleError(error, 'client-branding.service.loadInternal failed');
        this.isReadySubject.next(true);
        this.isReadySubject.complete();
      }
    );
  };

  private async preloadLogo(): Promise<void> {
    const url = this.logoUrl();
    if (!url) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve();
      };
      img.onerror = (err) => {
        console.log('client-branding.service img onerror', err);
        reject(err);
      };
    });
  }

  public logoUrl: Signal<string | null> = computed(() => {
    const data = this.data();
    if (!data) {
      return null;
    }
    const url = data?.files && data?.files[0] ? data.files[0].url : null;
    return updateLogoSize(url);
  });

  /**
   * Apply the theme's properties to the dom as css variables.
   * @param theme - api theme response.
   * @private
   */
  private setCSSVars(theme: { [key: string]: string }) {
    if (!theme) {
      return;
    }
    Object.keys(theme).forEach((key) => {
      if (key !== 'files' && !key.includes('mobile')) {
        this.document.documentElement.style.setProperty(
          getCssVarName(key),
          theme[key]
        );
      }
    });
  }

  private handleError(error: any, message: string): void {
    console.log(message, error);
    console.error(error);
    this.errorService.logSentryError(error, message);
  }

  public getColor(
    key: string,
    defaultColor: string,
    returnHex: boolean = false
  ): string {
    const data = this.data();
    const color = data && data[key];
    if (color) {
      if (returnHex) {
        return color;
      }

      return getCssVarName(key);
    }
    return defaultColor;
  }
}
