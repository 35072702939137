import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { map, mergeMap, filter } from 'rxjs/operators';
import { zip } from 'rxjs/observable/zip';

import * as _ from 'lodash';

import {
  ActiveFilterService,
  SortOptions,
  ConfigService,
  ViewService,
  ComparisonMode,
} from '../core';

import { SelectedSalesRepService } from './selected-sales-rep.service';
import { SalesRepService } from './sales-rep.service';
import { SalesRepCard } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';

class SalesRepRouteParams {
  salesRep: number;
  sortOn: string;
  desc: boolean;

  get sortOptions(): SortOptions {
    return { sortOn: this.sortOn, descending: this.desc };
  }

  clone = (): any => {
    return {
      sortOn: this.sortOn,
      desc: this.desc,
    };
  };
}

@Component({
  selector: 'rdo-sales-reps',
  templateUrl: 'sales-rep.component.html',
  styleUrls: ['sales-rep.component.scss'],
})
export class SalesRepComponent implements OnInit, OnDestroy {
  params: SalesRepRouteParams;
  private selectedSalesRep: SalesRepCard;
  private allSalesRepCards: Array<SalesRepCard>;
  private salesRepCards: Array<SalesRepCard>;
  private subscriptions = [];
  private currentChildRoute = '';
  private mode: ComparisonMode = ComparisonMode.Benchmark;
  private currentCardPage = 1;
  private cardPageSize = 20;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private filterService: ActiveFilterService,
    private salesRepService: SalesRepService,
    private selectedSalesRepService: SelectedSalesRepService,
    private configService: ConfigService,
    private viewService: ViewService
  ) {}

  ngOnInit() {
    if (this.params == null) {
      this.subscriptions.push(
        this.route.params.subscribe((params) => {
          if (this.params) {
            if (this.params.salesRep === parseInt(params.salesRep, 10)) {
              return;
            }
          }

          const p = new SalesRepRouteParams();
          p.salesRep = parseInt(params.salesRep, 10);
          p.sortOn = params.sortOn || '';
          p.desc = params.desc === 'true';

          const reloadCards = params.reloadCards === 'true';
          this.load(p, reloadCards);
        })
      );
    }

    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.route),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)
        )
        .subscribe((event) => {
          if (event.name) {
            // TODO: This should be rewritten this.currentChildRoute = event.name === 'dashboard' ? ...
            // eslint-disable-next-line
            event.name === 'dashboard'
              ? (this.currentChildRoute = '')
              : (this.currentChildRoute = event.name);
          }
        })
    );

    this.subscriptions.push(
      this.filterService.filterChange.subscribe(() => {
        this.load(this.params, true);
      })
    );
  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
    this.selectedSalesRepService.clear();
  }

  private load = (
    params: SalesRepRouteParams,
    reloadCards: boolean = false
  ) => {
    if (!this.allSalesRepCards || reloadCards) {
      zip(
        this.salesRepService
          .getSalesRepCards(params.sortOptions)
          .pipe(map((d) => <SalesRepCard[]>d)),
        this.viewService.getComparisonMode()
      ).subscribe((results: [SalesRepCard[], ComparisonMode]) => {
        this.allSalesRepCards = results[0];
        this.mode = results[1];
        this.params = params;
        const index = _.findIndex(
          this.allSalesRepCards,
          (x: SalesRepCard) => x.SalesRepId === params.salesRep
        );
        this.selectedSalesRep = this.allSalesRepCards[index];

        if (!this.selectedSalesRep) {
          this.router.navigate(['/sales-reps']);
        }

        if (this.allSalesRepCards.length <= this.cardPageSize) {
          this.salesRepCards = this.allSalesRepCards;
        } else {
          this.currentCardPage =
            index <= this.cardPageSize
              ? this.currentCardPage
              : index / this.cardPageSize;
          const previousTwenty = _.slice(
            this.allSalesRepCards,
            index <= this.cardPageSize ? 0 : index - this.cardPageSize,
            index
          );
          this.salesRepCards = _.union(
            previousTwenty,
            _.slice(this.allSalesRepCards, index, index + this.cardPageSize)
          );
        }

        this.selectedSalesRepService.notify(this.selectedSalesRep);
      });
    } else {
      const index = _.findIndex(
        this.allSalesRepCards,
        (x: SalesRepCard) => x.SalesRepId === params.salesRep
      );
      this.selectedSalesRep = this.allSalesRepCards[index];

      this.selectedSalesRepService.notify(this.selectedSalesRep);
      this.params = params;
    }
  };

  private select = (selected: any) => {
    this.selectedSalesRep = selected;

    const urlTree = this.currentChildRoute
      ? this.router.createUrlTree(
          [
            '../',
            selected.SalesRepId,
            this.params.clone(),
            this.currentChildRoute,
          ],
          { relativeTo: this.route }
        )
      : this.router.createUrlTree(
          ['../', selected.SalesRepId, this.params.clone()],
          { relativeTo: this.route }
        );

    this.router.navigateByUrl(urlTree);
    this.selectedSalesRepService.notify(this.selectedSalesRep);
  };

  private isSelected = (salesRep: any): boolean => {
    return (
      !this.selectedSalesRep ||
      this.selectedSalesRep.SalesRepId === salesRep.SalesRepId
    );
  };

  getPaginatedCards = () => {
    this.currentCardPage++;
    const offset = (this.currentCardPage - 1) * this.cardPageSize;
    const nextCards = _.drop(this.allSalesRepCards, offset).slice(
      0,
      this.cardPageSize
    );

    if (nextCards && nextCards.length) {
      this.salesRepCards = _.union(this.salesRepCards, nextCards);
    }
  };
}
