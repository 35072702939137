import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewChildren,
  QueryList,
  AfterViewInit,
  AfterViewChecked,
} from '@angular/core';
import { BreadcrumbService } from '../core/view/breadcrumb/breadcrumb.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import {
  IDataSet,
  FieldListService,
  PivotViewComponent,
  PivotFieldListComponent,
  ToolbarItems,
  Toolbar,
  EnginePopulatedEventArgs,
  FetchReportArgs,
  LoadReportArgs,
  RemoveReportArgs,
  RenameReportArgs,
  BeforeServiceInvokeEventArgs,
  CalculatedFieldCreateEventArgs,
  FieldDroppedEventArgs,
} from '../pivotcharts/ej2-angular-pivotview';
import { CustomGridsService } from './custom-grids.service';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import {
  AggregateTypes,
  DialogRenderer,
  NumberFormatting,
  PivotView,
  TreeViewRenderer,
} from '../pivotcharts/ej2-pivotview';
import { L10n, setCulture } from '../pivotcharts/ej2-base';
import { ErrorService } from '../core/errors/error.service';
import { Subscription } from 'rxjs';
import { GridMessageComponent } from '../core/grid/grid-error/grid-message.component';
import { MultiSelectDateOption } from '../models';
import { FirestoreService } from '../core/firestore/firestore.service';
import { toUpper } from 'lodash';
import { CG_FIELDS, CODENAMES } from '../core/constants';
import * as formulaValidator from './calculateField-validatior';
import * as Sentry from '@sentry/angular';
import { SENTRY_KEYS } from './custom-grids.constants';
import { Heap } from '../core/interface/heap/heap-interface';
import { createElement, remove } from '../pivotcharts/ej2-base';
import { Dialog } from '../pivotcharts/ej2-popups';
import { Router } from '@angular/router';
import { ExportSchedule } from './export-scheduler/export-schedule';
import { ExportSchedulerComponent } from './export-scheduler/export-scheduler.component';
import { ExportSchedulerService } from './export-scheduler/export-scheduler.service';
import { ExportsService, FormatService, LocaleService } from '../core';
import { SortModel } from '../pivotcharts/ej2-pivotview/src/pivotview/model/datasourcesettings-model';
import { FileManagerComponent } from '../core/file-manager/file-manager.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { FilterDataService } from '../filter';
import { FilterProfile } from '../filter/profiles/models';
import { FilterProfileService } from '../filter/profiles/filter-profile.service';

/* eslint-disable @typescript-eslint/no-this-alias */
declare let $: any;

@Component({
  selector: 'rdo-custom-grids',
  templateUrl: 'custom-grids.component.html',
  styleUrls: ['./custom-grids.component.scss'],
  providers: [FieldListService],
})
export class CustomGridsComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  public subscriptions = {};
  public isLoading: boolean = false;
  public pivotData: IDataSet[];
  public sidebarCollapsed = true;
  public toolbarOptions: ToolbarItems[];

  public _currentGridConfiguration: any;
  public get currentGridConfiguration() {
    return this._currentGridConfiguration;
  }
  public set currentGridConfiguration(config: any) {
    this._currentGridConfiguration = config;
    this.customGridsService.setCurrentGridConfiguration(
      this._currentGridConfiguration
    );
  }

  public aggregateTypesOption: AggregateTypes[];
  public showPivotView: boolean = true;
  public errorSubscriptionName = 'errorSubscription';
  public gridListSubscriptionName = 'gridListSubscription';
  public authServiceSubscriptionName = 'authServiceSubscription';
  public deleteConfigSubscriptionName = 'deleteSubscription';
  public dataRowLimit = 1048575;
  public dataSourceSettings: any;
  dates: Array<MultiSelectDateOption> = [];
  @ViewChild('pivotview')
  public pivotGridObj: PivotViewComponent;
  @ViewChild('gridMessage')
  public GridMessageComponent: GridMessageComponent;
  @ViewChild('exportScheduler')
  public ExportSchedulerComponent: ExportSchedulerComponent;
  public userIsCustomGridEditor: boolean = false;
  public exportSchedule: ExportSchedule;

  private fieldlistObj: PivotFieldListComponent; // probably deprecated, to be checked.
  private currentReportOriginalPersistData: any;
  @ViewChildren('PivotView_PivotFieldList')
  public fieldListChildren: QueryList<PivotFieldListComponent>;
  private confirmPopUp;
  private confirmPopUpOk;
  private intervals = [];
  private tokenExpirationTimer = 0;
  private exitPopUpConfirmed = false;
  private valuesWithDifferentCaption = {};
  private useRentedAsProductType = 0;
  constructor(
    private authenticationService: AuthenticationService,
    private breadcrumbService: BreadcrumbService,
    private customGridsService: CustomGridsService,
    private translateService: TranslateService,
    private errorService: ErrorService,
    private firestoreService: FirestoreService,
    private exportSchedulerService: ExportSchedulerService,
    private router: Router,
    private localeService: LocaleService,
    // need to import exportsServices so Firestore is initialized properly
    private exportsService: ExportsService,
    private materialdialog: MatDialog,
    private filterDataService: FilterDataService,
    private formatService: FormatService,
    private filterProfileService: FilterProfileService
  ) {
    this.handleToolbarTranslations();
  }

  public ngAfterViewInit(): void {
    this.trackHeapEvent('Custom Grids : RDO Tab : Selected', null);
    // eslint-disable-next-line
    // @ts-ignore
    this.intervals.tokenExpirationTimer = setInterval(() => {
      this.tokenExpirationTimer++;
      const minutesToRefreshToken = 900; // 900 seconds is 15 minutes
      if (this.tokenExpirationTimer === minutesToRefreshToken) {
        this.tokenExpirationTimer = 0;
        this.customGridsService.refreshToken().subscribe();
      }
    }, 1000);
    this.subscriptions[this.errorSubscriptionName] =
      this.errorService.errorRaised.subscribe((error) => {
        if (error === ErrorService.genericErrorMessage) {
          error = this.translateService.instant(
            'main.tabs.custom_grids.page_labels.generic_error_message'
          );
        }
        this.showModalDialog(error);
        this.isLoading = false;
        this.showPivotView = true;
        this.pivotGridObj.hideWaitingPopup();
      });
    // probably deprecated, to be checked.
    this.fieldListChildren.changes.subscribe(
      (comps: QueryList<PivotFieldListComponent>) => {
        this.fieldlistObj = comps.first;
      }
    );
  }

  ngAfterViewChecked(): void {
    try {
      this.customGridsService.updateFieldListFilterNames(this.pivotGridObj);
      this.customGridsService.setEditorFields(this.userIsCustomGridEditor);
      this.customGridsService.setGridListWidth();
    } catch (e) {
      console.error(`Error in ngAfterViewChecked: ${e}`);
    }
  }

  ConfigureGridClick(args: any) {
    this.trackHeapEvent('Custom Grids : Configure Grid : Selected', null);
    if (
      this.pivotGridObj.pivotFieldListModule &&
      this.pivotGridObj.pivotFieldListModule.dialogRenderer
    ) {
      this.dataSourceSettings = { ...this.pivotGridObj.dataSourceSettings };
      this.customGridsService.sortFieldListItemsIfShown();
      this.pivotGridObj.pivotFieldListModule.dialogRenderer.fieldListDialog.show();
    }
  }

  async ngOnInit() {
    const locale = this.localeService.getLocale();
    setCulture(locale);
    const syncfusionTranslations = {};
    syncfusionTranslations[locale] = this.translateService.instant(
      'main.tabs.custom_grids.syncfusion'
    );
    L10n.load(syncfusionTranslations);

    if (this.authenticationService._userInfoView.SelectedClient) {
      this.userIsCustomGridEditor =
        this.authenticationService.userIsOrImpersonatesCGEditor();
    }
    this.aggregateTypesOption = ['Sum', 'Count', 'Avg'];
    this.breadcrumbService.update([{ title: 'main.tabs.custom_grids.title' }]);
    this.loadGrids();
    this.overrideReportChangeFunction();
    // this.overrideApplyFormula();
    this.overrideGetEngineFunction();
    this.overrideToolbarOkBtnClick();
    this.replacePivotViewOnSuccess();
    this.replacePivotViewRenderPivotGrid();
    // this.replacePivotViewPreRender();
    this.replaceTreeRendererNodeStateChange();
    this.replaceTreeRendererDragStart();
    this.replaceTreeRendererDragStop();
    this.replaceNumberFormattingUpdateFormatting();
    this.replaceDialogRendererOnDeferUpdateClick();
    this.replaceToolbarRenameReport();
    //
    // setCurrencyCode('EUR');
    this.useRentedAsProductType =
      await this.filterProfileService.readPropertyAsync(
        CODENAMES.CN_SHOW_RENTED_AS
      );
  }

  showModalDialog(message, title?) {
    this.GridMessageComponent.showMessageDialog(message, title);
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key) => {
      const subscription: Subscription = this.subscriptions[key];
      subscription.unsubscribe();
    });
    Object.keys(this.intervals).forEach((key) => {
      clearInterval(this.intervals[key]);
    });
  }

  showExportScheduler() {
    const hasToManyRows =
      this.pivotGridObj.engineModule.dataRowCount > this.dataRowLimit;
    const isEditor =
      this.userIsCustomGridEditor &&
      this.authenticationService.hasGeographyFullAccess();
    this.ExportSchedulerComponent.showScheduleDialog(
      this.getReportId(this.currentGridConfiguration.name),
      this.currentGridConfiguration.target,
      this.exportSchedule,
      isEditor,
      hasToManyRows
    );
  }

  getScheduleExport(reportId: string) {
    this.exportSchedulerService
      .getGridExportSchedule(reportId, this.currentGridConfiguration.target)
      .subscribe((r) => {
        if (r) {
          this.exportSchedule =
            this.exportSchedulerService.transformJsonToExportSchedule(r);
        } else {
          this.exportSchedule = null;
        }
        this.updateToolbarItems();
      });
  }

  /**
   * Loads all grids available to the current user from FireStore and adds the
   * basic syncfusion toolbar configuration.
   */
  private loadGrids(newReportId: string = null) {
    this.isLoading = true;
    this.showPivotView = false;
    const currentConfigs = this.customGridsService.gridConfigs;
    if (currentConfigs && !newReportId) {
      this.configureGrids();
    } else {
      this.removeSubscriptionIfExists(this.gridListSubscriptionName);
      this.subscriptions[this.gridListSubscriptionName] =
        this.customGridsService.gridConfigsLoaded.subscribe(() => {
          this.configureGrids();
          if (newReportId) {
            this.currentGridConfiguration =
              this.customGridsService.gridConfigs[newReportId];
            const args: { name: string; reportName: string } = {
              name: 'loadReport',
              reportName: this.customGridsService.gridConfigs[newReportId].name,
            };
            this.loadReport(args);
          }
        });
      const localeFormat = this.formatService.getLocaleFormat();
      const currencyCode = localeFormat ? localeFormat.CurrencyCode : 'en-US';
      this.customGridsService.loadGridConfigurations(currencyCode);
    }

    this.updateToolbarItems();
  }

  configureGrids() {
    this.isLoading = false;
    this.showPivotView = true;
    this.fetchReport();
  }

  /**
   * Replaces CSS styles that contain variables with the text for the toolbar's labels.
   */
  private handleToolbarTranslations() {
    const toolbarMap: any = {
      'save-as': 'main.tabs.custom_grids.toolbar.save_as',
      save: 'main.tabs.custom_grids.toolbar.save',
      new: 'main.tabs.custom_grids.toolbar.new',
      remove: 'main.tabs.custom_grids.toolbar.remove',
      'number-formatting': 'main.tabs.custom_grids.toolbar.number_formatting',
      'configure-grid': 'main.tabs.custom_grids.toolbar.configure_grid',
      rename: 'main.tabs.custom_grids.toolbar.rename',
    };
    const root = document.querySelector(':root');
    Object.keys(toolbarMap).forEach((key) => {
      (<any>root).style.setProperty(
        '--' + key,
        '"' + this.translateService.instant(toolbarMap[key]) + '"'
      );
    });
  }

  /**
   * Toggles a sidebar that is no longer used.
   */
  toggleSidebar() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }

  /**
   * Overrides the report change function within the toolbar prototype so that
   * new grids can't be named the same way as the default grids.
   */
  private overrideReportChangeFunction() {
    // eslint-disable-next-line
    const _this = this;
    if (this.userIsCustomGridEditor) {
      // eslint-disable-next-line
      // @ts-ignore
      Toolbar.prototype.reportChange = function (args) {
        this.dropArgs = args;

        let reportCanBeUpdated = false;
        if (_this.currentReportOriginalPersistData) {
          this.parent.isModified = _this.customGridsService.isConfigModified(
            _this.currentReportOriginalPersistData.settings,
            JSON.parse(this.parent.getPersistData()).dataSourceSettings
          );
          reportCanBeUpdated = _this.reportCanBeUpdated(
            _this.getReportId(_this.currentReportOriginalPersistData.name)
          );
        }

        if (
          reportCanBeUpdated &&
          this.parent.isModified &&
          this.currentReport !== ''
        ) {
          this.createConfirmDialog(
            this.parent.localeObj.getConstant('alert'),
            this.parent.localeObj.getConstant('newReportConfirm')
          );
        } else {
          this.reportLoad(args);
        }
      };
    } else {
      // eslint-disable-next-line
      // @ts-ignore
      Toolbar.prototype.reportChange = function (args) {
        this.dropArgs = args;
        this.reportLoad(args);
      };
    }
  }

  private overrideGetEngineFunction() {
    // eslint-disable-next-line
    const originalFunction = PivotViewComponent.prototype.getEngine;
    PivotViewComponent.prototype.getEngine = function (
      action,
      drillItem,
      sortItem,
      aggField,
      cField,
      filterItem,
      memberName,
      rawDataArgs,
      editArgs
    ) {
      // Intercept and don't refresh grid for these operations
      if (action === 'onScroll' || action === 'onCalcOperation') {
        this.hideWaitingPopup();
        return;
      }
      const boundFunction = originalFunction.bind(
        this,
        action,
        drillItem,
        sortItem,
        aggField,
        cField,
        filterItem,
        memberName,
        rawDataArgs,
        editArgs
      );
      boundFunction();
    };
  }

  /**
   * Exports a spreadsheet with the data shown in the current grid.
   * This funciton is called by a custom button added within the toolbar.
   * @param args
   */
  export(args: any) {
    // let option1 = this.authenticationService._userInfoView.SelectedClient.RDOCustomGridsEditor;
    // let option2 = this.authenticationService._userInfoView.SelectedClient.RDOClientCustomGridsEditor;
    if (this.userIsCustomGridEditor) {
      const dialogConfig = this.getFileManagementModalConfiguration();
      this.materialdialog
        .open(FileManagerComponent, dialogConfig)
        .afterClosed()
        .subscribe((modalResponse) => {
          if (modalResponse?.primaryBtnClicked) {
            this.exportCustomGrid(
              modalResponse?.userIds,
              modalResponse?.notificationUserIds,
              modalResponse?.notificationMsg
            );
          }
        });
    } else {
      // For non-custom grid editors who can see the export button
      // the user selection screen is not displayed.
      this.exportCustomGrid();
    }
  }

  /**
   * Returns the proper configuration for the file management component
   * used to define which users will be granted access to the current
   * export.
   */
  private getFileManagementModalConfiguration(): MatDialogConfig {
    const translationPath = `main.tabs.custom_grids.page_labels.${this.currentGridConfiguration.name}`;
    let translatedReportName = this.translateService.instant(translationPath);
    if (translatedReportName === translationPath) {
      translatedReportName = this.currentGridConfiguration.name;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      RestrictedUsers: [
        /*{
                UserId: this.authenticationService.getUserId(),
                FirstName: this.authenticationService._userInfoView.FullName.split(' ')[0],
                LastName: this.authenticationService._userInfoView.FullName.split(' ')[1],
                UserName: this.authenticationService._userInfoView.Email
            }*/
      ],
      avoidApiInteractions: true,
      addCurrentUserAsRestricted: true,
      forceSelectionPermission: this.userIsCustomGridEditor,
      modalTitle: `${this.translateService.instant('main.core.file_manager.access_for')}
                         ${translatedReportName}`,
      currentGridBranches: this.customGridsService.getCurrentBranches(),
    };
    dialogConfig.width = '1060px';
    dialogConfig.height = '95vh';
    dialogConfig.id = 'file-manager-popup-modal';
    return dialogConfig;
  }

  /**
   * Exports a spreadsheet with the data shown in the current grid.
   * This funciton is called by the export funciton right after the
   * list of users that have permission to access this file have been
   * selected.
   * @param userIds: An array of userids (integers)
   */
  private exportCustomGrid(
    userIds: Array<number> = undefined,
    notificationUserIds: Array<number> = undefined,
    notificationMsg: string = undefined
  ): void {
    this.trackHeapEvent('Custom Grids : Excel : Selected', null);
    if (this.pivotGridObj.engineModule.dataRowCount > this.dataRowLimit) {
      this.showModalDialog(
        this.translateService.instant(
          'main.tabs.custom_grids.toolbar.excessive_rows_msg'
        )
      );
      return;
    }
    this.isLoading = true;
    const exportParams = { ...this.currentGridConfiguration };
    exportParams.settings = JSON.parse(
      this.pivotGridObj.getPersistData()
    ).dataSourceSettings;
    exportParams.settings.dataSource = [];
    exportParams.settings.ClientCode = toUpper(
      this.authenticationService._userInfoView.SelectedClient.ClientCode
    );
    if (!this.authenticationService._userInfoView.HasGeographyFullAccess) {
      this.addUsersGeoRestrictionsIfFiltersAreEmpty(exportParams.settings);
    }
    this.updateRowSortSettingsForExport(exportParams.settings);
    exportParams.settings.locale =
      this.formatService.getLocaleFormat().LocaleID;
    if (userIds && userIds.length) {
      exportParams.settings.allowedUserIds = userIds;
      exportParams.settings.notificationUserIds = notificationUserIds;
      exportParams.settings.notificationMsg = notificationMsg;
    }
    this.customGridsService.export(exportParams).subscribe(
      (path) => {
        this.firestoreService.trackExport(JSON.parse(path).record_path);
        this.isLoading = false;
      },
      (err) => {
        let errorMsg = this.translateService.instant(
          'main.tabs.custom_grids.scheduled_exports.messages.unable_to_create_spreadsheet'
        );
        if (err.statusText === 'Unknown Error') {
          errorMsg = this.translateService.instant(
            'main.tabs.custom_grids.page_labels.session_expired'
          );
          setTimeout(() => {
            window.location.reload();
          }, 10);
        }
        this.isLoading = false;
        throw Error(errorMsg);
      }
    );
  }

  addUsersGeoRestrictionsIfFiltersAreEmpty = (settings: any) => {
    const clientRegionKey = 'client_region';
    if (
      !_.find(
        settings.filterSettings,
        (filter) => filter.name === clientRegionKey
      ) &&
      (!_.find(settings.filters, (filter) => filter.name === clientRegionKey) ||
        !_.find(settings.rows, (row) => row.name === clientRegionKey) ||
        !_.find(settings.columns, (column) => column.name === clientRegionKey))
    ) {
      const regionFilter =
        this.customGridsService.createFilterSettingsfromFilter(clientRegionKey);
      regionFilter.items = _.map(
        this.filterDataService.regionsCache,
        (region) => region.description
      );
      settings.filterSettings.push(regionFilter);
    }

    const clientDistrictKey = 'client_district';
    if (
      !_.find(
        settings.filterSettings,
        (filter) => filter.name === clientDistrictKey
      ) &&
      (!_.find(settings.filters, (filter) => filter.name === clientRegionKey) ||
        !_.find(settings.rows, (row) => row.name === clientDistrictKey) ||
        !_.find(
          settings.columns,
          (column) => column.name === clientDistrictKey
        ))
    ) {
      const districtFilter =
        this.customGridsService.createFilterSettingsfromFilter(
          clientDistrictKey
        );
      districtFilter.items = _.map(
        this.filterDataService.districtsCache,
        (district) => district.description
      );
      settings.filterSettings.push(districtFilter);
    }

    const locationCodeKey = 'location_code';
    if (
      !_.find(
        settings.filterSettings,
        (filter) => filter.name === locationCodeKey
      ) &&
      (!_.find(settings.filters, (filter) => filter.name === locationCodeKey) ||
        !_.find(settings.rows, (row) => row.name === locationCodeKey) ||
        !_.find(settings.columns, (column) => column.name === locationCodeKey))
    ) {
      const locationCodeFilter =
        this.customGridsService.createFilterSettingsfromFilter(locationCodeKey);
      locationCodeFilter.items = _.map(
        this.filterDataService.branchesCache,
        (branch) => branch.description
      );
      settings.filterSettings.push(locationCodeFilter);
    }
  };

  /**
   * Returns the current firestore id associated to a given grid name.
   * @param reportName
   */
  getReportId = (reportName: string) => {
    for (const key in this.customGridsService.gridConfigs) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.customGridsService.gridConfigs,
          key
        )
      ) {
        const element = this.customGridsService.gridConfigs[key];
        const translatedName = this.translateService.instant(
          `main.tabs.custom_grids.page_labels.${element.name}`
        );
        const elementName = element.name;
        if (
          translatedName.toString().toLowerCase().trim() ===
            reportName.toLowerCase().trim() ||
          elementName.toString().toLowerCase().trim() ===
            reportName.toLowerCase().trim()
        ) {
          return key;
        }
      }
    }
  };

  getGridConfigFromReporName = (reportName: string) => {
    for (const key in this.customGridsService.gridConfigs) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.customGridsService.gridConfigs,
          key
        )
      ) {
        const element = this.customGridsService.gridConfigs[key];
        const translatedName = this.translateService.instant(
          `main.tabs.custom_grids.page_labels.${element.name}`
        );
        let elementName = element.name;
        if (
          translatedName !==
          `main.tabs.custom_grids.page_labels.${element.name}`
        ) {
          elementName = translatedName;
        }
        if (
          elementName.toLowerCase().trim() === reportName.toLowerCase().trim()
        ) {
          return element;
        }
      }
    }
  };

  isTemplateGrid(reportId: string): boolean {
    const report = this.customGridsService.gridConfigs[reportId];
    return report.scope === 'global';
  }

  /**
   * Cleans non-used calculated formulas and format settings. Calculated formulas are cleaned by
   * removing the formulas that are not present within the values array.
   * Format settings are cleaned by removing the ones that are not present within rows, columns,
   * values or calculated fields.
   * Updating the content of dataSourceSettings triggers another engine populated event, so it must
   * only be overriden when there are fields that should be removed.
   */
  cleanUnused = () => {
    if (this.pivotGridObj && this.pivotGridObj.dataSourceSettings) {
      if (
        this.pivotGridObj.dataSourceSettings.calculatedFieldSettings &&
        this.pivotGridObj.dataSourceSettings.calculatedFieldSettings.length
      ) {
        // Removes unused calculated settings
        const tempFieldSetting =
          this.pivotGridObj.dataSourceSettings.calculatedFieldSettings.filter(
            (cs) => {
              const found = (<any>(
                this.pivotGridObj.dataSourceSettings.values
              )).find((value) => value.name === cs.name);
              return found;
            }
          );
        if (
          tempFieldSetting.length <
          this.pivotGridObj.dataSourceSettings.calculatedFieldSettings.length
        ) {
          this.pivotGridObj.dataSourceSettings.calculatedFieldSettings =
            tempFieldSetting;
        }

        // Removes unused format settings
        const tempFormatSettings =
          this.pivotGridObj.dataSourceSettings.formatSettings.filter((fs) => {
            const foundInValues = (<any>(
              this.pivotGridObj.dataSourceSettings.values
            )).find((value) => value.name === fs.name);
            const foundInColumns = (<any>(
              this.pivotGridObj.dataSourceSettings.columns
            )).find((col) => col.name === fs.name);
            const foundInRows = (<any>(
              this.pivotGridObj.dataSourceSettings.rows
            )).find((row) => row.name === fs.name);
            const foundInCalc = (<any>(
              this.pivotGridObj.dataSourceSettings.calculatedFieldSettings
            )).find((calc) => calc.name === fs.name);
            return (
              foundInValues || foundInColumns || foundInRows || foundInCalc
            );
          });
        if (
          tempFormatSettings.length <
          this.pivotGridObj.dataSourceSettings.formatSettings.length
        ) {
          this.pivotGridObj.dataSourceSettings.formatSettings =
            tempFormatSettings;
        }
      }
    }
  };

  // Override the Sava and SaveAs report options so we control the validation and messaging
  private overrideToolbarOkBtnClick() {
    const cgComponent = this;
    // eslint-disable-next-line
    // @ts-ignore
    const originalApplyFormula = Toolbar.prototype.okBtnClick;
    // eslint-disable-next-line
    // @ts-ignore
    Toolbar.prototype.okBtnClick = function () {
      const reportInput = this.dialog.element.querySelector(
        '.e-pivotview-report-input'
      );
      if (reportInput && reportInput.value.trim() === '') {
        reportInput.focus();
        return;
      }
      const reportData = this.parent.getPersistData();
      if (
        this.dialog.header === this.parent.localeObj.getConstant('save') ||
        this.dialog.header === this.parent.localeObj.getConstant('saveAs')
      ) {
        this.action = 'Save';
        this.currentReport = reportInput.value;
        const saveArgs = {
          report: reportData,
          action: 'saveAs',
          reportName: reportInput.value,
        };
        this.parent.trigger('saveReport', saveArgs);
        this.parent.isModified = false;
        // this.updateReportList();
        this.dialog.hide();

        return;
      } else if (
        this.dialog.header === this.parent.localeObj.getConstant('rename') &&
        reportInput.value &&
        reportInput.value !== ''
      ) {
        if (
          !cgComponent.customGridsService.isGridNameValid(reportInput.value)
        ) {
          cgComponent.pivotGridObj.pivotCommon.errorDialog.createErrorDialog(
            this.translateService.instant(
              'main.tabs.custom_grids.page_labels.error'
            ),
            this.translateService.instant(
              'main.tabs.custom_grids.page_labels.reports_names_rules'
            )
          );
          this.isLoading = false;
          return;
        }
        if (
          !cgComponent.customGridsService.gridNameIsUnique(
            null,
            reportInput.value
          )
        ) {
          cgComponent.pivotGridObj.pivotCommon.errorDialog.createErrorDialog(
            this.translateService.instant(
              'main.tabs.custom_grids.page_labels.error'
            ),
            this.translateService.instant(
              'main.tabs.custom_grids.page_labels.report_already_exists'
            )
          );
          this.isLoading = false;
          return;
        }
      }
      const boundFunction = originalApplyFormula.bind(this);
      boundFunction();
    };
  }

  private replacePivotViewOnSuccess() {
    // eslint-disable-next-line
    // @ts-ignore
    const originalPivotViewOnSuccess = PivotView.prototype.onSuccess;
    const customGridsComponent = this;
    // eslint-disable-next-line
    // @ts-ignore
    PivotView.prototype.onSuccess = function () {
      customGridsComponent.pivotViewOnSuccessOverride(
        this,
        originalPivotViewOnSuccess
      );
    };
  }

  public pivotViewOnSuccessOverride(
    pivotViewContext,
    originalPivotViewOnSuccess
  ) {
    if (pivotViewContext.request.readyState === XMLHttpRequest.DONE) {
      if (pivotViewContext.request.status === 400) {
        this.isLoading = false;
        this.showPivotView = true;
        this.showModalDialog(
          pivotViewContext.localeObj.getConstant('genericErrorText')
        );
        const boundFunction = originalPivotViewOnSuccess.bind(pivotViewContext);
        boundFunction();
      } else {
        const transaction = Sentry.getCurrentHub().getScope().getTransaction();
        if (transaction) {
          const span = transaction.startChild({
            op: SENTRY_KEYS.TOTAL_TIME_SPAN,
            tags: {
              reportName: this.currentReportOriginalPersistData
                ? this.currentReportOriginalPersistData.name
                : 'Unknown',
            },
            data: {
              reportName: this.currentReportOriginalPersistData
                ? this.currentReportOriginalPersistData.name
                : 'Unknown',
            },
          });
          Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(span));
        }

        if (pivotViewContext.currentAction === 'fetchFieldMembers') {
          const engine = JSON.parse(pivotViewContext.request.responseText);
          const currentMembers = JSON.parse(engine.members);
          // eslint-disable-next-line
          for (let i = 0; i < currentMembers.length; i++) {
            const translatedTextLabel = `main.tabs.custom_grids.labels.${currentMembers[i].FormattedText.toLowerCase()}`;
            const translatedText =
              this.translateService.instant(translatedTextLabel);
            currentMembers[i].FormattedText =
              translatedText === translatedTextLabel
                ? currentMembers[i].FormattedText
                : translatedText;
          }
          const boundFunction = originalPivotViewOnSuccess.bind(
            pivotViewContext,
            { currentMembers }
          );
          boundFunction();
        } else {
          const boundFunction =
            originalPivotViewOnSuccess.bind(pivotViewContext);
          boundFunction();
        }

        this.valuesWithDifferentCaption = {};
        _.forEach(
          this.currentReportOriginalPersistData?.settings?.values,
          (value) => {
            if (value.name !== value.caption) {
              this.valuesWithDifferentCaption[value.name] =
                this.getTranslatedValueForLabel('', value.caption);
            }
          }
        );
        if (!this.customGridsService.pivotGridObj) {
          this.customGridsService.setPivotGridObj(this.pivotGridObj);
        }
      }
    }
  }

  private replaceTreeRendererNodeStateChange() {
    const originalTreeRendererNodeStateChange =
      // eslint-disable-next-line
      // @ts-ignore
      TreeViewRenderer.prototype.nodeStateChange;
    const customGridsComponent = this;
    // eslint-disable-next-line
    // @ts-ignore
    TreeViewRenderer.prototype.nodeStateChange = function (args) {
      customGridsComponent.TreeRendererNodeStateChangeOverride(
        this,
        args,
        originalTreeRendererNodeStateChange
      );
    };
  }

  public TreeRendererNodeStateChangeOverride(
    pivotViewContext,
    args,
    originalTreeRendererNodeStateChange
  ) {
    if (args.action === 'check') {
      this.startSentryTransaction(SENTRY_KEYS.FIELD_LIST_CHECK);
    }

    const boundFunction = originalTreeRendererNodeStateChange.bind(
      pivotViewContext,
      args
    );
    boundFunction();

    if (args.action === 'check') {
      this.finishSentryTransaction();
    }
  }

  private replaceTreeRendererDragStart() {
    // eslint-disable-next-line
    // @ts-ignore
    const originalTreeRendererDragStart = TreeViewRenderer.prototype.dragStart;
    const customGridsComponent = this;
    // eslint-disable-next-line
    // @ts-ignore
    TreeViewRenderer.prototype.dragStart = function (args) {
      customGridsComponent.TreeRendererDragStartOverride(
        this,
        args,
        originalTreeRendererDragStart
      );
    };
  }

  public TreeRendererDragStartOverride(
    pivotViewContext,
    args,
    originalTreeRendererDragStart
  ) {
    this.startSentryTransaction(SENTRY_KEYS.DRAG_DROP);
    const boundFunction = originalTreeRendererDragStart.bind(
      pivotViewContext,
      args
    );
    boundFunction();
  }

  private replaceTreeRendererDragStop() {
    // eslint-disable-next-line
    // @ts-ignore
    const originalTreeRendererDragStop = TreeViewRenderer.prototype.dragStop;
    const customGridsComponent = this;
    // eslint-disable-next-line
    // @ts-ignore
    TreeViewRenderer.prototype.dragStop = function (args) {
      customGridsComponent.TreeRendererDragStopOverride(
        this,
        args,
        originalTreeRendererDragStop
      );
    };
  }

  public TreeRendererDragStopOverride(
    pivotViewContext,
    args,
    originalTreeRendererDragStop
  ) {
    const boundFunction = originalTreeRendererDragStop.bind(
      pivotViewContext,
      args
    );
    boundFunction();

    this.finishSentryTransaction();
  }

  private replaceNumberFormattingUpdateFormatting() {
    const originalNumberFormattingUpdateFormatting =
      // eslint-disable-next-line
      // @ts-ignore
      NumberFormatting.prototype.updateFormatting;
    const customGridsComponent = this;
    // eslint-disable-next-line
    // @ts-ignore
    NumberFormatting.prototype.updateFormatting = function (args) {
      customGridsComponent.NumberFormattingUpdateFormattingOverride(
        this,
        args,
        originalNumberFormattingUpdateFormatting
      );
    };
  }

  public NumberFormattingUpdateFormattingOverride(
    pivotViewContext,
    args,
    originalNumberFormattingUpdateFormatting
  ) {
    this.startSentryTransaction(SENTRY_KEYS.CONDITIONAL_FORMATTING);

    const boundFunction = originalNumberFormattingUpdateFormatting.bind(
      pivotViewContext,
      args
    );
    boundFunction();

    // this.finishSentryTransaction();
  }

  private replaceToolbarRenameReport() {
    // eslint-disable-next-line
    // @ts-ignore
    const originaToolbarRenameReport = Toolbar.prototype.renameReport;
    const customGridsComponent = this;
    // eslint-disable-next-line
    // @ts-ignore
    Toolbar.prototype.renameReport = function (args) {
      customGridsComponent.ToolbarRenameReportOverride(
        this,
        args,
        originaToolbarRenameReport
      );
    };
  }

  public ToolbarRenameReportOverride(
    toolbarContext,
    args,
    originaToolbarRenameReport
  ) {
    const oldReportId = this.getReportId(
      this.currentReportOriginalPersistData.name
    );
    if (this.isTemplateGrid(oldReportId)) {
      this.pivotGridObj.pivotCommon.errorDialog.createErrorDialog(
        this.translateService.instant(
          'main.tabs.custom_grids.page_labels.error'
        ),
        this.translateService.instant(
          'main.tabs.custom_grids.page_labels.global_reports_cannot_be_renamed'
        )
      );
      this.isLoading = false;
      return;
    }
    this.startSentryTransaction(SENTRY_KEYS.RENAME);

    const boundFunction = originaToolbarRenameReport.bind(toolbarContext, args);
    boundFunction();
  }

  private replaceDialogRendererOnDeferUpdateClick() {
    const originalDialogRendererOnDeferUpdateClick =
      // eslint-disable-next-line
      // @ts-ignore
      DialogRenderer.prototype.onDeferUpdateClick;
    const customGridsComponent = this;
    // eslint-disable-next-line
    // @ts-ignore
    DialogRenderer.prototype.onDeferUpdateClick = function (args) {
      customGridsComponent.DialogRendererOnDeferUpdateClickOverride(
        this,
        args,
        originalDialogRendererOnDeferUpdateClick
      );
    };
  }

  public DialogRendererOnDeferUpdateClickOverride(
    pivotViewContext,
    args,
    originalDialogRendererOnDeferUpdateClick
  ) {
    this.startSentryTransaction(SENTRY_KEYS.APPLY_CONFIG);

    const boundFunction = originalDialogRendererOnDeferUpdateClick.bind(
      pivotViewContext,
      args
    );
    boundFunction();
  }

  private replacePivotViewRenderPivotGrid() {
    const originalPivotViewRenderPivotGrid =
      /* eslint-disable-next-line @typescript-eslint/unbound-method */
      PivotView.prototype.renderPivotGrid;
    const customGridsComponent = this;
    PivotView.prototype.renderPivotGrid = function () {
      customGridsComponent.pivotViewRenderPivotGridOverride(
        this,
        originalPivotViewRenderPivotGrid
      );
    };
  }

  public pivotViewRenderPivotGridOverride(
    pivotViewContext,
    originalPivotViewRenderPivotGrid
  ) {
    const boundFunction =
      originalPivotViewRenderPivotGrid.bind(pivotViewContext);
    boundFunction();

    this.finishSentryTransaction();
  }

  // private replacePivotViewPreRender() {
  //     let originalPivotViewPreRender = PivotView.prototype['preRender'];
  //     let customGridsComponent = this;
  //     PivotView.prototype['renderPivotGrid'] = function() {
  //         customGridsComponent.pivotViewPreRenderOverride(this, originalPivotViewPreRender);
  //     };
  // }

  // public pivotViewPreRenderOverride(pivotViewContext, originalPivotViewPreRender) {
  //     const boundFunction = originalPivotViewPreRender.bind(pivotViewContext);
  //     boundFunction();

  //     this.pivotGridObj.defaultLocale = this.translateService.instant('main.tabs.custom_grids.syncfusion');
  //     this.pivotGridObj.localeObj = new L10n(this.pivotGridObj.getModuleName(), this.pivotGridObj.defaultLocale, this.pivotGridObj.locale);
  //     this.pivotGridObj.renderContextMenu();
  //     this.pivotGridObj.isDragging = false;
  //     this.pivotGridObj.addInternalEvents();
  // }
  /**
   * Saves the currently selected grid into FireStore. If it already exists,
   * it gets updated, otherwise a new one is created and stored.
   * @param args
   * @returns
   */
  saveReport(args: any) {
    if (
      args.reportName &&
      args.reportName.toLowerCase() !==
        this.pivotGridObj.localeObj.getConstant('defaultReport').toLowerCase()
    ) {
      this.isLoading = true;
      if (
        args.action === 'saveAs' &&
        args.reportName &&
        args.reportName.trim().toLowerCase() !== ''
      ) {
        const reportName = args.reportName.trim();
        this.trackHeapEvent('Custom Grids : Save As : Selected', {
          RdoReportName: args.reportName,
          RdoFields: 'tbd',
        });
        if (!this.customGridsService.isGridNameValid(reportName)) {
          this.pivotGridObj.pivotCommon.errorDialog.createErrorDialog(
            this.translateService.instant(
              'main.tabs.custom_grids.page_labels.error'
            ),
            this.translateService.instant(
              'main.tabs.custom_grids.page_labels.reports_names_rules'
            )
          );
          this.isLoading = false;
          return;
        }
        // post
        if (!this.customGridsService.gridNameIsUnique(null, reportName)) {
          this.pivotGridObj.pivotCommon.errorDialog.createErrorDialog(
            this.translateService.instant(
              'main.tabs.custom_grids.page_labels.error'
            ),
            this.translateService.instant(
              'main.tabs.custom_grids.page_labels.report_already_exists'
            )
          );
          this.isLoading = false;
          return;
        }
        this.startSentryTransaction(SENTRY_KEYS.SAVE_AS);

        const newReportJson = JSON.parse(args.report);
        this.customGridsService.setFilterOverrides(
          newReportJson.dataSourceSettings.filterSettings,
          newReportJson.dataSourceSettings.filters,
          newReportJson.dataSourceSettings.columns,
          this.pivotGridObj.dataSourceSettings.filterSettings
        );
        delete newReportJson.dataSourceSettings.dataSource;
        const newReport = { ...this.currentGridConfiguration };
        newReport.scope = 'client';
        newReport.name = args.reportName;

        newReport.settings = newReportJson.dataSourceSettings;
        this.currentReportOriginalPersistData = { ...newReport };
        return this.customGridsService
          .createConfiguration(
            newReport,
            this.authenticationService._userInfoView.Email
          )
          .subscribe((newGridWithId) => {
            this.loadGrids(newGridWithId.grid_id);
          });
      } else if (args.name === 'saveReport' && args.reportName) {
        const oldReportId = this.getReportId(args.reportName);
        const originalGridConfig =
          this.customGridsService.gridConfigs[oldReportId];
        // put
        if (!this.reportCanBeUpdated(oldReportId)) {
          this.pivotGridObj.pivotCommon.errorDialog.createErrorDialog(
            this.translateService.instant(
              'main.tabs.custom_grids.page_labels.error'
            ),
            this.translateService.instant(
              'main.tabs.custom_grids.page_labels.global_reports_cannot_be_updated'
            )
          );
          this.isLoading = false;
          return;
        } else {
          const updatedReportJson = JSON.parse(args.report);

          this.customGridsService.setFilterOverrides(
            updatedReportJson.dataSourceSettings.filterSettings,
            updatedReportJson.dataSourceSettings.filters,
            updatedReportJson.dataSourceSettings.columns,
            this.pivotGridObj.dataSourceSettings.filterSettings
          );
          delete updatedReportJson.dataSourceSettings.dataSource;
          this.currentGridConfiguration.settings =
            updatedReportJson.dataSourceSettings;
          delete updatedReportJson.dataSourceSettings.dataSource;
          this.customGridsService.gridConfigs[oldReportId] = {
            ...this.currentGridConfiguration,
          };
          this.currentReportOriginalPersistData = {
            ...this.currentGridConfiguration,
          };
          return this.customGridsService
            .updateConfiguration(
              oldReportId,
              this.currentGridConfiguration,
              this.authenticationService._userInfoView.Email
            )
            .subscribe(() => {
              this.isLoading = false;
              this.showModalDialog(
                this.translateService.instant(
                  'main.tabs.custom_grids.page_labels.report_saved_successfully'
                ),
                this.translateService.instant(
                  'main.tabs.custom_grids.page_labels.success'
                )
              );
            });
        }
      }
    }
  }

  /**
   * Looks for the list of reports and assigns it to ????
   * @param args
   * @returns
   */
  fetchReport(args: FetchReportArgs = {}) {
    let transaction = Sentry.getCurrentHub().getScope().getTransaction();
    if (!transaction || transaction.name !== 'Save As') {
      transaction = Sentry.startTransaction({
        name: SENTRY_KEYS.LOAD_GRID_TRANSACTION,
        tags: {
          reportName: this.currentReportOriginalPersistData
            ? this.currentReportOriginalPersistData.name
            : 'Unknown',
          reportSource: this.currentReportOriginalPersistData
            ? this.currentReportOriginalPersistData.target
            : 'Unknown',
          userId: this.authenticationService.getUserId(),
          clientId: this.authenticationService.getClientId(),
        },
      });
      Sentry.getCurrentHub().configureScope((scope) =>
        scope.setSpan(transaction)
      );
    }

    const reportList: string[] = [];

    for (const key in this.customGridsService.gridConfigs) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.customGridsService.gridConfigs,
          key
        )
      ) {
        const element = this.customGridsService.gridConfigs[key];
        if (element.name) {
          const translatedName = this.translateService.instant(
            `main.tabs.custom_grids.page_labels.${element.name}`
          );
          if (
            translatedName ===
            `main.tabs.custom_grids.page_labels.${element.name}`
          ) {
            reportList.push(element.name);
          } else {
            reportList.push(translatedName);
          }
        }
      }
    }
    if (
      this.pivotGridObj &&
      this.pivotGridObj.toolbarModule &&
      this.currentGridConfiguration
    ) {
      let elementName = this.currentGridConfiguration.name;
      const translatedName = this.translateService.instant(
        `main.tabs.custom_grids.page_labels.${elementName}`
      );
      if (
        translatedName !== `main.tabs.custom_grids.page_labels.${elementName}`
      ) {
        elementName = translatedName;
      }
      (<any>this.pivotGridObj.toolbarModule).currentReport = elementName;
      // this.pivotGridObj.localeObj.setLocale(this.localeService.getLocale());
    }

    this.customGridsService.hideGridIfNoReportSelected(
      this.currentGridConfiguration
    );
    return (args.reportName = reportList);
  }
  public getTranslatedValueForLabel(label: string, defaultValue: string) {
    let translationKey = `main.tabs.custom_grids.labels.${label}`;
    let value = this.translateService.instant(translationKey);
    if (value === translationKey) {
      translationKey = `main.tabs.custom_grids.labels.${defaultValue}`;
      value = this.translateService.instant(translationKey);
      if (value === translationKey) {
        return defaultValue;
      }
    }
    return value;
  }
  /**
   * This function is called by ???? andt
   */
  async loadReport(args: LoadReportArgs) {
    this.trackHeapEvent('Custom Grids : Report : Selected', {
      RdoReportName: args.reportName,
    });
    const reportId = this.getReportId(args.reportName || (<any>args).value);
    this.currentGridConfiguration = {
      ...this.customGridsService.gridConfigs[reportId],
    };

    this.isLoading = true;
    this.showPivotView = false;
    this.exportSchedule = null;
    this.getScheduleExport(reportId);

    this.currentGridConfiguration.settings.filters.forEach((filter) => {
      filter.caption = this.getTranslatedValueForLabel(
        filter.name,
        filter.caption
      );
    });
    this.currentGridConfiguration.settings.values.forEach((value) => {
      if (value.type !== 'CalculatedField') {
        value.caption = this.getTranslatedValueForLabel(
          value.name,
          value.caption
        );
      }
    });
    this.currentGridConfiguration.settings.rows.forEach((value) => {
      value.caption = this.getTranslatedValueForLabel(
        value.name,
        value.caption
      );
    });
    this.currentGridConfiguration.settings.filterSettings.forEach((value) => {
      value.caption = this.getTranslatedValueForLabel(
        value.name,
        value.caption
      );
    });
    this.currentGridConfiguration.settings.columns.forEach((value) => {
      value.caption = this.getTranslatedValueForLabel(
        value.name,
        value.caption
      );
    });

    await this.customGridsService.loadDefaultFilterSettingsForTarget(
      this.currentGridConfiguration.target,
      this.currentGridConfiguration.settings.filterSettings
    );
    await this.customGridsService.applyDefaultFilterSettings(
      this.currentGridConfiguration.settings.filters,
      this.currentGridConfiguration.settings.filterSettings,
      this.currentGridConfiguration.target
    );

    if (
      this.customGridsService.filtersettingsHasField(
        this.currentGridConfiguration.settings.filterSettings,
        CG_FIELDS.MONTH
      )
    ) {
      await this.customGridsService
        .loadMonthFieldValues(this.currentGridConfiguration.target)
        .then((r) => {
          this.customGridsService.applyDefaultColumnSettings(
            this.currentGridConfiguration.settings.columns,
            this.currentGridConfiguration.settings.filterSettings,
            CG_FIELDS.MONTH
          );
        });
    }

    this.dataSourceSettings = {
      ...this.currentGridConfiguration.settings,
    };

    this.updateToolbarItems();
    this.currentReportOriginalPersistData = {
      ...this.currentGridConfiguration,
    };
    setTimeout(() => {
      this.showPivotView = true;
    }, 100);
  }

  updateToolbarItems() {
    if (this.userIsCustomGridEditor && this.currentGridConfiguration) {
      const config = this.getGridConfigFromReporName(
        this.currentGridConfiguration.name
      );
      if (config && config.scope === 'client') {
        this.toolbarOptions = [
          'Load',
          'Save',
          'SaveAs',
          'Remove',
          'Rename',
          'FieldList',
          'NumberFormatting',
        ] as ToolbarItems[];
      } else {
        this.toolbarOptions = [
          'Load',
          'Save',
          'SaveAs',
          'FieldList',
          'NumberFormatting',
        ] as ToolbarItems[];
      }
    } else if (this.currentGridConfiguration) {
      this.toolbarOptions = ['Load', 'FieldList'] as ToolbarItems[];
    } else {
      this.toolbarOptions = ['Load'] as ToolbarItems[];
    }
  }

  /**
   * Executed by syncfusion when a grid is attempted to be deleted. It's not being
   * used right now since the delete button is disabled.
   * TODO: If this ever gets used again, we should show a spinner upon usage and also
   * prevent the user from being able to delete a global grid.
   * @param args
   */
  removeReport(args: RemoveReportArgs) {
    const reportId = this.getReportId(args.reportName);
    const config = this.getGridConfigFromReporName(args.reportName);
    if (this.userIsCustomGridEditor && config && config.scope === 'client') {
      this.currentGridConfiguration = null;
      const currentReport = this.customGridsService.gridConfigs[reportId];
      delete this.customGridsService.gridConfigs[reportId];
      this.removeSubscriptionIfExists(this.deleteConfigSubscriptionName);
      return this.customGridsService
        .deleteConfig(reportId, currentReport.scope, currentReport.target)
        .subscribe(() => {});
    }
  }

  removeSubscriptionIfExists(subscriptionName: string) {
    const subscription: Subscription = this.subscriptions[subscriptionName];
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  /**
   * Executed by Syncfusion when attempting to rename a grid.
   * @param args
   * @returns
   */
  renameReport(args: RenameReportArgs) {
    this.pivotGridObj.showWaitingPopup();
    const configWithNameUpdated = {
      ...this.currentGridConfiguration,
      name: args.rename,
    };
    const configId = this.getReportId(this.currentGridConfiguration.name);
    this.currentGridConfiguration.name = args.rename;
    this.customGridsService.gridConfigs[configId] =
      this.currentGridConfiguration;
    return this.customGridsService
      .updateConfiguration(
        configId,
        configWithNameUpdated,
        this.authenticationService._userInfoView.Email
      )
      .subscribe((updatedConfigId) => {
        this.pivotGridObj.hideWaitingPopup();
        if (updatedConfigId) {
          this.currentGridConfiguration = configWithNameUpdated;
        }
      });
  }

  /**
   * Manually adds a new calculated field since syncfusion is supper bugged
   * @param event
   */
  async calculatedFieldCreate(event: CalculatedFieldCreateEventArgs) {
    this.isLoading = true;
    const error = formulaValidator.validateCalculatedField(
      event.calculatedField,
      this.pivotGridObj.engineModule.fieldList,
      event.isEdit
    );
    if (error) {
      this.isLoading = false;
      event.error = this.translateService.instant(error);
    }
    if (!event.error) {
      await this.customGridsService
        .validateFormula(
          event.calculatedField.formula,
          this.authenticationService._userInfoView.SelectedClient.ClientID.toString(),
          this.currentGridConfiguration.target
        )
        .toPromise()
        .then((r: any) => {
          if (!r.isValid) {
            event.error = r.error;
          }

          event.calculatedFieldObj.saveCalculatedField(event);

          this.trackHeapEvent(
            'Custom Grids : New Calculated Fields : Created',
            {
              RdoReportName: this.currentReportOriginalPersistData.name,
              RdoFieldName: event.calculatedField.name,
              RdoFormula: event.calculatedField.formula,
            }
          );
        });
    } else {
      event.calculatedFieldObj.saveCalculatedField(event);
      this.trackHeapEvent('Custom Grids : New Calculated Fields : Created', {
        RdoReportName: this.currentReportOriginalPersistData.name,
        RdoFieldName: event.calculatedField.name,
        RdoFormula: event.calculatedField.formula,
      });
    }
  }

  /**
   * Gets called by Syncfusion when a field is dropped either from values, rows,
   * columns or filters. In Here filters and calculated functions must be filtered
   * when a calculated funciton field gets dropped, so that we don't end up with
   * unsued calculated funcitons that can't be overriden.
   */
  async onFieldDropped(args: FieldDroppedEventArgs) {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await this.updateSortSettingsOnFieldDrop(args);
    if (!args.droppedAxis) {
      const index =
        this.pivotGridObj.dataSourceSettings.filterSettings.findIndex(
          (r) => r.name === args.droppedField.name
        );
      if (index !== -1) {
        this.pivotGridObj.dataSourceSettings.filterSettings.splice(index, 1);
      }
      const listItem = this.pivotGridObj.engineModule.fieldList[args.fieldName];
      if (listItem && listItem.isSelected) {
        listItem.isSelected = false;
        this.pivotGridObj.updateDataSource();
      }
    } else {
      const index =
        this.pivotGridObj.dataSourceSettings.filterSettings.findIndex(
          (r) => r.name.toLowerCase() === args.droppedField.name.toLowerCase()
        );
      if (index === -1) {
        if (args.droppedField.name === CG_FIELDS.MONTH) {
          this.addDefaultMonthColumnFilterOnDrop(CG_FIELDS.MONTH);
          if (
            this.pivotGridObj.engineModule.fieldList[CG_FIELDS.MONTH].sort ===
            'None'
          ) {
            this.pivotGridObj.engineModule.fieldList[CG_FIELDS.MONTH].sort =
              'Descending';
          }
        }

        if (args.droppedField.name === CG_FIELDS.TTM_END_MONTH) {
          this.addDefaultFilterOnDrop(CG_FIELDS.TTM_END_MONTH);
        }
      } else {
        if (
          args.droppedField.name === CG_FIELDS.MONTH &&
          this.pivotGridObj.engineModule.fieldList[CG_FIELDS.MONTH].sort ===
            'None'
        ) {
          this.pivotGridObj.engineModule.fieldList[CG_FIELDS.MONTH].sort =
            'Descending';
          this.pivotGridObj.updateDataSource();
        }
      }
    }
  }

  updateSortSettingsOnFieldDrop(args: FieldDroppedEventArgs) {
    const updatedSortModel = [];
    if (!args.droppedAxis || args.droppedAxis === 'rows') {
      this.pivotGridObj.dataSourceSettings.sortSettings.forEach((r) => {
        if (r.name !== args.fieldName) {
          updatedSortModel.push(r);
        }
      });
    }
    if (args.droppedAxis === 'rows') {
      const sortModel: SortModel = {
        name: args.fieldName,
        order: 'Ascending',
      };
      updatedSortModel.push(sortModel);
    }
    this.pivotGridObj.dataSourceSettings.sortSettings = updatedSortModel;
  }

  updateRowSortSettingsForExport(settings: any) {
    const addedRows = [];
    settings.rows.forEach((row) => {
      let matched = false;
      const sortModel: SortModel = {
        name: row.name,
        order: 'Ascending',
      };

      settings.sortSettings.forEach((sort) => {
        if (row.name === sort.name) {
          matched = true;
        }
      });

      if (!matched) {
        addedRows.push(sortModel);
      }
    });

    addedRows.forEach((row) => {
      settings.sortSettings.push(row);
    });
  }

  addDefaultFilterOnDrop(fieldName) {
    const fom = [{ name: fieldName }];
    const index = this.pivotGridObj.dataSourceSettings.filterSettings.findIndex(
      (r) => r.name === fieldName
    );
    if (index !== -1) {
      this.pivotGridObj.dataSourceSettings.filterSettings.splice(index, 1);
    }
    this.customGridsService.applyDefaultMonthFilterSettings(
      fom,
      this.pivotGridObj.dataSourceSettings.filterSettings,
      fieldName
    );
  }
  addDefaultMonthColumnFilterOnDrop(fieldName) {
    const fom = [{ name: fieldName }];
    const index = this.pivotGridObj.dataSourceSettings.filterSettings.findIndex(
      (r) => r.name === fieldName
    );
    if (index !== -1) {
      this.pivotGridObj.dataSourceSettings.filterSettings.splice(index, 1);
    }
    this.customGridsService.applyDefaultColumnSettings(
      fom,
      this.pivotGridObj.dataSourceSettings.filterSettings,
      fieldName
    );
  }

  /**
   * This function is always called right before calling the API. It's currently used
   * only to display a spinner and to add the corresponding Authorization token as well as a traceid for sentry
   * @param args
   */
  beforeServiceInvoke(args: BeforeServiceInvokeEventArgs) {
    if (args.action !== 'fetchFieldMembers') {
      this.isLoading = true;
    }
    this.pivotGridObj.pageSettings.rowSize = 10000;
    this.pivotGridObj.pageSettings.columnSize = 1000;
    // remove orphaned filter settings
    const newFilterSettings = [];

    this.pivotGridObj.dataSourceSettings.filterSettings.forEach((fs) => {
      const column = this.pivotGridObj.dataSourceSettings.columns.find(
        (r) => r.name === fs.name
      );
      const row = this.pivotGridObj.dataSourceSettings.rows.find(
        (r) => r.name === fs.name
      );
      const filter = this.pivotGridObj.dataSourceSettings.filters.find(
        (r) => r.name === fs.name
      );
      if (column || row || filter) {
        newFilterSettings.push(fs);
      }
    });
    if (
      newFilterSettings.length !==
      this.pivotGridObj.dataSourceSettings.filterSettings.length
    ) {
      this.pivotGridObj.dataSourceSettings.filterSettings = [
        ...newFilterSettings,
      ];
    }

    let transaction = Sentry.getCurrentHub().getScope().getTransaction();
    if (!transaction || transaction.name !== 'Save As') {
      transaction = Sentry.startTransaction({
        name: SENTRY_KEYS.LOAD_GRID_TRANSACTION,
        tags: {
          reportName: this.currentReportOriginalPersistData
            ? this.currentReportOriginalPersistData.name
            : 'Unknown',
          reportSource: this.currentReportOriginalPersistData
            ? this.currentReportOriginalPersistData.target
            : 'Unknown',
          userId: this.authenticationService.getUserId(),
          clientId: this.authenticationService.getClientId(),
        },
      });
      Sentry.getCurrentHub().configureScope((scope) =>
        scope.setSpan(transaction)
      );
    }

    args.request.setRequestHeader(
      'sentry-trace',
      `${transaction.getTraceContext().trace_id}-${transaction.getTraceContext().span_id}`
    );
    args.request.setRequestHeader(
      'clientid',
      this.authenticationService._userInfoView.SelectedClient.ClientID.toString()
    );
    args.request.setRequestHeader(
      'use-rented-as-product-type',
      this.useRentedAsProductType === 1 ? 'true' : 'false'
    );

    this.subscriptions[this.authServiceSubscriptionName] =
      this.authenticationService.getAuthToken().subscribe((token) => {
        args.request.setRequestHeader(
          'Authorization',
          `Bearer ${token.AccessToken}`
        );
        args.request.setRequestHeader(
          'Accept-Language',
          this.localeService.getLocale()
        );
      });
  }

  beforeToolbarRender(args: any) {
    if (this.currentGridConfiguration && this.currentGridConfiguration.name) {
      const iconSrc = this.exportSchedule ? 'published' : 'date-range';
      if (
        this.authenticationService._userInfoView.HasClientAccessToDownloads &&
        this.authenticationService._userInfoView.HasClientAccessToExportData
      ) {
        args.customToolbar.splice(9, 0, {
          template: `<button id="export-scheduler"
                    class="e-tbar-btn e-control e-btn e-lib e-icon-btn" style="height: 40px; margin: 5px;">
                    <img id='icnExportSchedule' src="/assets/images/${iconSrc}.svg">
                    ${this.translateService.instant('main.tabs.custom_grids.toolbar.export_schedule')}&nbsp;`,
          align: 'right',
          click: this.showExportScheduler.bind(this),
        });
      }
      if (
        this.authenticationService._userInfoView.HasClientAccessToDownloads &&
        this.authenticationService._userInfoView.HasClientAccessToExportData
      ) {
        args.customToolbar.push({
          template: `<button id="download-excel-button"
                    class="btn btn-success btn-inverse">
                        <i class="fa fa-fw fa-file-excel-o" [ngClass]="{'fa-lg': size === 'md'}"></i>&nbsp;
                        Excel</button>`,
          align: 'right',
          click: this.export.bind(this),
        });
      }
      if (args && args.customToolbar) {
        const button = <HTMLElement>(
          args.customToolbar.find((r) => r.id === 'PivotViewfieldlist')
        );
        if (button) {
          button.click = this.ConfigureGridClick.bind(this);
        }
      }
    }
  }

  enginePopulated(args: EnginePopulatedEventArgs) {
    if (args.dataSourceSettings) {
      this.currentGridConfiguration.settings = JSON.parse(
        this.pivotGridObj.getPersistData()
      ).dataSourceSettings;
    }
    if (args.pivotValues && args.pivotValues.length === 0) {
      // force display of no results row when no values returned
      args.pivotValues = null;
    }
    if (this.pivotGridObj) {
      if (this.pivotGridObj.engineModule) {
        for (const key in this.pivotGridObj.engineModule.fieldList) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.pivotGridObj.engineModule.fieldList,
              key
            )
          ) {
            const gridElement = this.pivotGridObj.engineModule.fieldList[key];
            if (this.valuesWithDifferentCaption[key]) {
              gridElement.caption = this.valuesWithDifferentCaption[key];
            }
            gridElement.caption = this.getTranslatedValueForLabel(
              gridElement.id,
              gridElement.caption
            );
          }
        }
      }
      this.pivotGridObj.dataSourceSettings.values.forEach((value) => {
        const field = this.pivotGridObj.engineModule.fieldList[value.name];
        if (field) {
          value.caption = field.caption;
        }
      });

      this.pivotGridObj.dataSourceSettings.filters.forEach((filter) => {
        filter.caption = this.getTranslatedValueForLabel(
          filter.name,
          filter.caption
        );
      });
      this.dataSourceSettings = { ...this.pivotGridObj.dataSourceSettings };
    }
    if (this.fieldlistObj && this.pivotGridObj) {
      this.fieldlistObj.updateView(this.pivotGridObj);
    }

    // if args has datasourcesettings loading isn't done yet.
    if (!args.dataSourceSettings) {
      this.isLoading = false;
    }

    return args;
  }

  /**
   * create a heap event with the given name.  property data may be null or an object representing additional infomraiton such as
   * which dropdown item was selected during a dropdown item selection event
   * @param args
   * @returns
   */
  private trackHeapEvent(eventName: string, propertydata: any) {
    try {
      ((window as any).heap as Heap)?.track(eventName, propertydata); // we keep property data (such as which dropdown item a user selected) as passed in unadorned with additional 'rdo%' properties
    } catch (e) {}
  }
  startSentryTransaction(name, data = {}, tags = {}) {
    const finalTags = {
      ...{
        reportName: this.currentReportOriginalPersistData
          ? this.currentReportOriginalPersistData.name
          : 'Unknown',
        reportSource: this.currentReportOriginalPersistData
          ? this.currentReportOriginalPersistData.target
          : 'Unknown',
        userId: this.authenticationService.getUserId(),
        clientId: this.authenticationService.getClientId(),
      },
      ...tags,
    };
    const transaction = Sentry.startTransaction({
      name,
      tags: finalTags || {},
    });

    if (transaction) {
      Sentry.getCurrentHub().configureScope((scope) =>
        scope.setSpan(transaction)
      );
      const span = transaction.startChild({
        op: name,
        data: data || {},
      });
      Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(span));
    }
  }

  finishSentryTransaction() {
    const span = Sentry.getCurrentHub().getScope().getSpan();
    if (span) {
      span.finish();
    }
    const transaction = Sentry.getCurrentHub().getScope().getTransaction();
    if (transaction) {
      transaction.finish();
      Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(null));
    }
  }
  reportCanBeUpdated(reportId: string) {
    if (environment.cgTemplatesCanBeModified) {
      if (
        this.authenticationService._userInfoView.SelectedClient
          .RDOCustomGridsEditor
      ) {
        return true;
      }
      if (
        this.authenticationService._userInfoView.SelectedClient
          .RDOClientCustomGridsEditor &&
        !this.isTemplateGrid(reportId)
      ) {
        return true;
      }
    } else {
      if (!reportId) {
        return true;
      } else if (this.isTemplateGrid(reportId)) {
        return false;
      } else {
        return this.userIsCustomGridEditor;
      }
    }
  }

  existUnsavedChanges(url: any) {
    const customGridsComponent = this;
    if (
      this.exitPopUpConfirmed ||
      !this.currentReportOriginalPersistData ||
      !this.pivotGridObj ||
      !this.reportCanBeUpdated(
        this.getReportId(this.currentReportOriginalPersistData.name)
      )
    ) {
      return false;
    }
    if (
      this.customGridsService.isConfigModified(
        this.currentReportOriginalPersistData.settings,
        JSON.parse(this.pivotGridObj.getPersistData()).dataSourceSettings
      )
    ) {
      if (
        document.getElementById(this.pivotGridObj.element.id + '_ConfirmDialog')
      ) {
        remove(
          document.getElementById(
            this.pivotGridObj.element.id + '_ConfirmDialog'
          ).parentElement
        );
      }
      const errorDialog = createElement('div', {
        id: this.pivotGridObj.element.id + '_ConfirmDialog',
        className: 'e-pivot-error-dialog',
      });
      this.pivotGridObj.element.appendChild(errorDialog);
      this.confirmPopUp = new Dialog({
        animationSettings: { effect: 'Fade' },
        allowDragging: true,
        showCloseIcon: true,
        enableRtl: this.pivotGridObj.enableRtl,
        locale: this.pivotGridObj.locale,
        header: this.pivotGridObj.localeObj.getConstant('alert'),
        content: this.pivotGridObj.localeObj.getConstant('newReportConfirm'),
        isModal: true,
        visible: true,
        closeOnEscape: true,
        target: document.body,
        width: 'auto',
        height: 'auto',
        position: { X: 'center', Y: 'center' },
        buttons: [
          {
            buttonModel: {
              content: this.pivotGridObj.localeObj.getConstant('yes'),
              isPrimary: true,
              cssClass: 'e-ok-btn',
            },
            click() {
              const reportInput =
                customGridsComponent.confirmPopUp.element.querySelector(
                  '.e-pivotview-report-input'
                );
              if (reportInput && reportInput.value.trim() === '') {
                reportInput.focus();
                return;
              }
              const reportData =
                customGridsComponent.pivotGridObj.getPersistData();

              this.action = 'Save';
              // this.currentReport = reportInput.value;
              const saveArgs = {
                report: reportData,
                action: 'save',
                reportName:
                  customGridsComponent.currentReportOriginalPersistData.name,
              };
              customGridsComponent.pivotGridObj.trigger('saveReport', saveArgs);
              customGridsComponent.pivotGridObj.isModified = false;
              customGridsComponent.confirmPopUp.hide();
              customGridsComponent.exitPopUpConfirmed = true;
              if (
                document.getElementById(
                  customGridsComponent.pivotGridObj.element.id +
                    '_ConfirmDialog'
                )
              ) {
                remove(
                  document.getElementById(
                    customGridsComponent.pivotGridObj.element.id +
                      '_ConfirmDialog'
                  ).parentElement
                );
              }

              // show ok dialog
              const okDialog = createElement('div', {
                id:
                  customGridsComponent.pivotGridObj.element.id +
                  '_ConfirmDialogOk',
                className: 'e-pivot-error-dialog',
              });
              customGridsComponent.pivotGridObj.element.appendChild(okDialog);
              customGridsComponent.confirmPopUpOk = new Dialog({
                animationSettings: { effect: 'Fade' },
                allowDragging: true,
                showCloseIcon: true,
                enableRtl: customGridsComponent.pivotGridObj.enableRtl,
                locale: customGridsComponent.pivotGridObj.locale,
                header:
                  customGridsComponent.pivotGridObj.localeObj.getConstant(
                    'alert'
                  ),
                content: 'Report Saved Successfully',
                isModal: true,
                visible: true,
                closeOnEscape: true,
                target: document.body,
                width: 'auto',
                height: 'auto',
                close() {
                  customGridsComponent.router.navigate([url]);
                },
                position: { X: 'center', Y: 'center' },
                buttons: [
                  {
                    buttonModel: {
                      content:
                        customGridsComponent.pivotGridObj.localeObj.getConstant(
                          'yes'
                        ),
                      isPrimary: true,
                      cssClass: 'e-ok-btn',
                    },
                    click() {
                      if (
                        document.getElementById(
                          customGridsComponent.pivotGridObj.element.id +
                            '_ConfirmDialogOk'
                        )
                      ) {
                        remove(
                          document.getElementById(
                            customGridsComponent.pivotGridObj.element.id +
                              '_ConfirmDialogOk'
                          ).parentElement
                        );
                      }

                      customGridsComponent.router.navigate([url]);
                      return;
                    },
                  },
                ],
              });
              customGridsComponent.confirmPopUpOk.isStringTemplate = true;
              customGridsComponent.confirmPopUpOk.appendTo(okDialog);
              customGridsComponent.confirmPopUpOk.element.querySelector(
                '.e-dlg-header'
              ).innerHTML =
                customGridsComponent.pivotGridObj.localeObj.getConstant(
                  'alert'
                );
              return;
            },
          },
          {
            buttonModel: {
              content: this.pivotGridObj.localeObj.getConstant('no'),
              cssClass: 'e-cancel-btn',
            },
            click() {
              customGridsComponent.exitPopUpConfirmed = true;
              if (
                document.getElementById(
                  customGridsComponent.pivotGridObj.element.id +
                    '_ConfirmDialog'
                )
              ) {
                remove(
                  document.getElementById(
                    customGridsComponent.pivotGridObj.element.id +
                      '_ConfirmDialog'
                  ).parentElement
                );
              }
              customGridsComponent.router.navigate([url]);
            },
          },
        ],
      });
      this.confirmPopUp.isStringTemplate = true;
      this.confirmPopUp.appendTo(errorDialog);
      this.confirmPopUp.element.querySelector('.e-dlg-header').innerHTML =
        this.pivotGridObj.localeObj.getConstant('alert');
    }
    return this.customGridsService.isConfigModified(
      this.currentReportOriginalPersistData.settings,
      JSON.parse(this.pivotGridObj.getPersistData()).dataSourceSettings
    );
  }
}
