import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, zip } from 'rxjs';
import { FilterProfile } from './models/filter-profile';
import { FilterApiProfileService } from './filter-api-profile.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RawFilterProfile } from './models/raw-filter-profile';
import { FilterProfileMap } from './models/filter-profile-map';
import { PlatformFilterProfile } from './models/platform-filter-profile';
import { CODENAMES } from '../../core/constants';
import { PlatformProfilesMap } from './models/platform-profiles-map';
import { ProfileLoadingStage } from './models/profile-loading-stage';
import { Exception } from 'handlebars';
import { FilterValues } from '../../models/filter-values';
import { MonthSetting } from './models/month-settings';
import { MultiSelectDateOption } from '../../models';

export declare type ToastSource = 'read' | 'create' | 'update' | 'delete';

const DEBUG_MISSING_FIELDS = false;

/**
 * The partialProfileMap is a map of fields that have a corresponding input. These fieldNames, if they
 * don't exist in the saved filter, should receive the default value. If the fieldName is not
 * in the partial map the value should be returned as is. The partialProfileMap has to be updated
 * any time an optional filter is added to the application, so we are using this as our source of truth.
 * @param fieldName
 * @param clientValue
 * @param partialProfileMap
 */
export const getMissingFieldValue = (
  fieldName: string,
  clientValue,
  partialProfileMap: any
): string | number | boolean | any[] => {
  const partialProfileKeys = Object.keys(partialProfileMap);
  if (!partialProfileKeys.includes(fieldName)) {
    return clientValue;
  }

  if (DEBUG_MISSING_FIELDS) {
    console.log('***********************');
    console.log(
      'filter-profile.service.getMissingFieldValue  MISSING FIELD!!!!!!  \nfieldName:',
      fieldName,
      '\nclientValue:',
      clientValue,
      '\npartialProfileMap:',
      partialProfileMap
    );
    console.log('***********************');
  }

  const defaultValue = partialProfileMap[fieldName];

  // What fields would hit this conditional.  If you look at the two arrays involved
  // this shouldn't be possible, but it was definitely happening for 1 field at least.
  if (defaultValue === null && FilterProfileMap.isArrayFilter(fieldName)) {
    return [null];
  }

  return defaultValue;
};

const getFieldMapForTestingMissingProfileFields = (map) => {
  map.user_defined = map.user_defined.map((profile) => {
    const fields = profile.fields.filter(
      (field) =>
        field.field_name !== 'filter.transaction.operated' &&
        field.field_name !== 'filter.equipment.prime-units'
    );
    profile.fields = fields;
    return profile;
  });
  return map;
};

@Injectable({
  providedIn: 'root',
})
export class FilterProfileService {
  public clientProfile: BehaviorSubject<FilterProfile> =
    new BehaviorSubject<FilterProfile>(new FilterProfile());
  public profilesList: BehaviorSubject<FilterProfile[]> = new BehaviorSubject<
    FilterProfile[]
  >([]);
  public currentProfile: BehaviorSubject<FilterProfile | null> =
    new BehaviorSubject<FilterProfile | null>(null);
  public triggerApplyProfiles: () => any;
  public readFilterComponentValues: () => any;
  public showManagement: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public showSaveAs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public platformProfileMap: PlatformProfilesMap;
  public profileMap: FilterProfileMap;
  public loadingStage = ProfileLoadingStage.STARTING;
  private readonly NO_PROFILE_SELECTED_PLACEHOLDER =
    'main.filters.profiles.list.no_selected';
  private readonly TEMP_PROFILE_ID = -999;
  public currentfilterProfileApplied: FilterProfile | null;
  public selectedFilterValues: FilterValues = new FilterValues();
  public selectedMonthIds: number[] = [];
  public selectedMonthCode: string = MonthSetting.LATEST_FULL_MONTH;
  public applyFilterChanges = new Subject();
  public monthMessgeDisplayed = false;
  constructor(
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private apiService: FilterApiProfileService
  ) {
    this.loadProfiles();
  }

  public applyFiltersFromComponent(callback: () => void | null = null) {
    this.triggerApplyProfiles();
    setTimeout(() => {
      if (callback) {
        callback();
      }
    }, 200);
  }

  public triggerApplyFilters() {
    this.applyFilterChanges.next();
  }

  /**
   * Loads the list of profiles without their details,
   * meaning only name, id and isDefault values are obtained.
   */
  public loadProfiles(profileId: number = null, setLoaded: boolean = false) {
    this.currentfilterProfileApplied = null;
    this.apiService
      .getProfilesList()
      .subscribe((platformProfileMap: PlatformProfilesMap) => {
        if (DEBUG_MISSING_FIELDS) {
          this.platformProfileMap =
            getFieldMapForTestingMissingProfileFields(platformProfileMap);
          console.log(
            'DEBUG filter-profile.service  platformProfileMap ',
            this.platformProfileMap
          );
        } else {
          this.platformProfileMap = platformProfileMap;
        }

        this.loadProfile(profileId, setLoaded);
        this.toggleSpinner(false);
      });
  }

  public readRawFilterComponentData() {
    const result = JSON.parse(JSON.stringify(this.selectedFilterValues));
    result.months = this.selectedMonthIds;
    result.monthsCode = this.selectedMonthCode;
    result.currencySymbol = this.readClientProfileProperty(
      'client.currency-symbol'
    );
    return Object.assign(new RawFilterProfile(), result);
  }

  public ApplyFilterValuesToFilterProfile(
    filterProfile: FilterProfile,
    rawFilterProfileData: RawFilterProfile
  ): FilterProfile {
    filterProfile.months = rawFilterProfileData?.months;
    filterProfile.monthsCode = rawFilterProfileData?.monthsCode;

    // Map label based fields
    const propertyMap = FilterProfile.getLabel2PropertyMap();
    Object.keys(propertyMap).forEach((label) => {
      const field = propertyMap[label];
      filterProfile[label] = rawFilterProfileData[field];
    });

    return filterProfile;
  }

  public getFilterProfileFromFilterValues(): FilterProfile {
    const rawFilterProfileData = this.readRawFilterComponentData();
    const newFilterProfile = Object.assign(
      new FilterProfile(),
      this.currentProfile.value
    );

    return this.ApplyFilterValuesToFilterProfile(
      newFilterProfile,
      rawFilterProfileData
    );
  }

  public reconcileSelectedMonthsWithAvailableMonths = (
    availableDates: MultiSelectDateOption[],
    selectedMonthIds: number[],
    currentMonthId: number,
    monthsCode: string
  ) => {
    const selectedMonths = selectedMonthIds.map((id) =>
      availableDates.find((date) => date.id === id)
    );
    const selectedMonthsFiltered = selectedMonths.filter((date) => date);
    // If no months are selected, select the last available month
    if (selectedMonthsFiltered.length === 0) {
      return [availableDates.find((date) => date.id < currentMonthId).id];
    }

    const missingMonths = this.getMissingMonthsCount(
      selectedMonthsFiltered,
      selectedMonthIds
    );
    // If more than one month is selected and one or more are missing, show a warning message
    if (
      !this.monthMessgeDisplayed &&
      missingMonths > 0 &&
      selectedMonthIds.length > 1
    ) {
      this.toasterService.warning(
        this.translateService.instant('main.core.missing_month'),
        null,
        { timeOut: 10000 }
      );
      this.monthMessgeDisplayed = true;
    }
    const currentYear = availableDates[0].date.getFullYear();
    for (let i = 0; i < missingMonths; i++) {
      if (monthsCode !== MonthSetting.YEAR_TO_DATE) {
        selectedMonthsFiltered.push(
          availableDates.find(
            (date) =>
              selectedMonthIds.indexOf(date.id) === -1 &&
              date.id < currentMonthId &&
              !selectedMonthsFiltered.find((smf) => smf.id === date.id)
          )
        );
      }
    }

    return selectedMonthsFiltered.map((date) => date.id);
  };

  public getMissingMonthsCount = (
    availableDataDates: MultiSelectDateOption[],
    selectedMonthIds: number[]
  ) => {
    let unavailableMonths = 0;

    selectedMonthIds.forEach((id) => {
      if (!availableDataDates.find((date) => date.id === id)) {
        unavailableMonths++;
      }
    });
    return unavailableMonths;
  };

  /**
   * Loads a profile with the given id from the platform profile map.
   */
  public loadProfile(profileId: number = null, setLoaded: boolean = false) {
    const tempProfile =
      this.profileMap &&
      this.profileMap.userProfiles &&
      this.profileMap.userProfiles.length
        ? this.profileMap.userProfiles.find(
            (x) => x && x.isTempProfile && x.profileId === this.TEMP_PROFILE_ID
          )
        : null;
    const noProfileSelectedStr = this.translateService.instant(
      this.NO_PROFILE_SELECTED_PLACEHOLDER
    );
    const filterProfileMap = FilterProfileMap.buildFromPlatformProfiles(
      this.platformProfileMap,
      noProfileSelectedStr
    );
    if (tempProfile && filterProfileMap && filterProfileMap.userProfiles) {
      filterProfileMap.userProfiles.push(tempProfile);
    }
    this.profileMap = JSON.parse(JSON.stringify(filterProfileMap));
    this.loadingStage = setLoaded
      ? ProfileLoadingStage.LOADED
      : this.loadingStage;
    if (FilterProfileMap.validateMap(filterProfileMap)) {
      this.profilesList.next(filterProfileMap.userProfiles);
      this.clientProfile.next(filterProfileMap.clientProfile);
      this.setCurrentProfile(profileId, !profileId);
    }
  }

  /**
   * Returns true if the given profile is unique and contains
   * one or more valid characters.
   */
  public profileNameIsValid(profileName: string): boolean {
    let isValid = !!(profileName && profileName.trim().length);
    if (isValid) {
      const found = this.profilesList.value.find(
        (x) =>
          x.profileName.toLocaleLowerCase() ===
          profileName.toLocaleLowerCase().trim()
      );
      isValid = !found;
    }
    return isValid;
  }

  /**
   * Returns a property with the given code name from the
   * current filter profile.
   */
  public readCurrentProfileProperty(codeName: string) {
    const currentProfile = this.currentProfile.value;
    // eslint-disable-next-line no-prototype-builtins
    const propertyExists = currentProfile?.hasOwnProperty(codeName);

    const result =
      currentProfile && propertyExists ? currentProfile[codeName] : undefined;

    return result;
  }

  /**
   * Returns a property with the given code name from the
   * current filter profile.
   */
  public readClientProfileProperty(codeName: string) {
    const clientProfile = this.clientProfile.value;
    // eslint-disable-next-line no-prototype-builtins
    return clientProfile && clientProfile.hasOwnProperty(codeName)
      ? clientProfile[codeName]
      : undefined;
  }

  /**
   * Waits for the current profile to be valid and then
   * returns a property with the given code name from the
   * current filter profile.
   */
  public readPropertyAsync(codeName: string): Promise<any> {
    const obs2Return = new Observable((obs) => {
      try {
        this.wait4CurrentProfile().subscribe(() => {
          const currentProfile = this.currentProfile.value;

          const result =
            // eslint-disable-next-line no-prototype-builtins
            currentProfile && currentProfile.hasOwnProperty(codeName)
              ? currentProfile[codeName]
              : undefined;
          obs.next(result);
          obs.complete();
        });
      } catch (err) {
        obs.error(err);
      }
    });
    return obs2Return.toPromise();
  }

  /**
   * Function that returns an observable which will resolve
   * once a value is available for the current filter profile.
   */
  public wait4CurrentProfile(): Observable<boolean> {
    return new Observable<boolean>((obs) => {
      try {
        this.currentProfile.subscribe((value) => {
          if (FilterProfileMap.validateProfile(value)) {
            obs.next(true);
            obs.complete();
          }
        });
      } catch (err) {
        obs.error(err);
      }
    });
  }

  /**
   * Sets the current profile based on the given profileId
   * or sets the default profile if no profileId is received.
   */
  public setCurrentProfile(
    profileId: number = null,
    useDefault: boolean = false
  ): void {
    const validProfileId =
      !!profileId && (profileId > 0 || profileId === this.TEMP_PROFILE_ID);
    const defaultProfile = this.profileMap?.userProfiles.find(
      (x: FilterProfile) =>
        (x.isDefault && useDefault) ||
        (!useDefault && validProfileId && x.profileId === profileId)
    );

    if (!defaultProfile) {
      const copiedClientProfile = JSON.parse(
        JSON.stringify(this.clientProfile.value)
      );
      this.currentProfile.next(copiedClientProfile);
    } else {
      const mergedProfile = this.mergeProfileWithClient(defaultProfile);
      this.currentProfile.next(mergedProfile);
    }
  }

  /**
   * Client profiles contain all available fields, while user
   * profiles only contain specific user configurations. To use
   * a user profile, missing fields have to be obtained from the
   * client profile.
   * This function returns a copy of the given profile but adding
   * missing fields from the client profile.
   */
  public mergeProfileWithClient(savedProfile: FilterProfile): FilterProfile {
    const profile = JSON.parse(JSON.stringify(savedProfile));
    const savedProfileKeys = Object.keys(savedProfile);

    // In case the saved profile has filters that should be hidden, but
    // we don't need to remove them because they are soft deleted in db
    // as soon as the default profile removes a shared filter.
    // https://rouseservices.atlassian.net/browse/ANA-6968

    const partialProfile = FilterProfileMap.getPartialEmptyProfile();

    // Add default filters from the client default profile
    Object.keys(this.clientProfile.value).forEach((profileKey) => {
      if (!savedProfileKeys.includes(profileKey)) {
        const clientValue = this.clientProfile.value[profileKey];

        profile[profileKey] = getMissingFieldValue(
          profileKey,
          clientValue,
          partialProfile
        );
      }
    });
    // These flags must always be true in user profiles
    profile['filter.general.allow-disallow-outliers'] = true;
    profile['filter.general.allow-disallow-verticals'] = true;
    profile['filter.general.allow-disallow-rouse-categories'] = true;

    if (DEBUG_MISSING_FIELDS) {
      console.log('filter-profile.service.mergeProfileWithClient  ', profile);
    }

    return profile;
  }

  public updateProfileInList(profile: FilterProfile) {
    const found = this.profilesList.value.find(
      (x) => x.profileId === profile.profileId
    );
    if (found) {
      const index = this.profilesList.value.indexOf(found);
      this.profilesList.value[index] = profile;
    } else if (profile.profileId === -1) {
      this.clientProfile.next(profile);
    }
  }

  /**
   * Removes fields that should not be saved and modifies the
   * way in which cycle bills are defined.
   */
  public getCleanProfile(profile: FilterProfile): any {
    const changedProfile = {
      profileId: profile.profileId,
      profileName: profile.profileName,
      isDefault: profile.isDefault,
    };
    const keys2Ignore = [...Object.keys(changedProfile), 'isTempProfile'];
    const originalClientProfile = this.clientProfile.value;
    const originalClientProfileKeys = Object.keys(originalClientProfile).filter(
      (x) => !keys2Ignore.includes(x)
    );
    Object.keys(profile)
      .filter((x) => !keys2Ignore.includes(x))
      .forEach((key) => {
        let newValue = profile[key];
        if (
          key === CODENAMES.CN_TRANSACTION_CYCLE_BILL &&
          Array.isArray(newValue) &&
          newValue.length
        ) {
          newValue = newValue[0] * 10 + newValue[newValue.length - 1];
        }
        const existsInClientProfile = originalClientProfileKeys.includes(key);
        if (existsInClientProfile) {
          changedProfile[key] = profile[key];
        }
      });
    return changedProfile;
  }

  public setEmptyProfile(emptyProfileName: string): Observable<FilterProfile> {
    const obs2Return = new Observable<FilterProfile>((obs) => {
      // Create empty temp profile for an alert
      const clientProfileCopy = JSON.parse(
        JSON.stringify(this.clientProfile.value)
      );
      const partialProfile = FilterProfileMap.getPartialEmptyProfile();
      Object.keys(partialProfile).forEach((key) => {
        clientProfileCopy[key] = partialProfile[key];
      });
      clientProfileCopy.isDefault = false;
      clientProfileCopy.profileId = -999;
      clientProfileCopy.profileName = emptyProfileName;
      clientProfileCopy.isTempProfile = true;

      // Update references and add to lists
      this.removeTempProfile();
      this.currentProfile.next(clientProfileCopy);
      this.profilesList.value.push(clientProfileCopy);
      this.profileMap.userProfiles.push(clientProfileCopy);
      this.notifyProfileListSuscriptors();
      obs.next(clientProfileCopy);
      obs.complete();
    });
    return obs2Return;
  }

  /**
   * Removes the temp profile from the corresponding lists.
   * The profilesList contains the current profiles.
   * The profileMap.userProfiles Contains the profile as obtained
   * from the API, without any end user changes.
   */
  public removeTempProfile(avoidRemovingCurrent: boolean = false) {
    const tempIsCurrent =
      this.currentProfile.value.profileId === this.TEMP_PROFILE_ID;
    const shouldRemove = !(tempIsCurrent && avoidRemovingCurrent);
    if (shouldRemove) {
      const tempProfileIndex = this.profilesList.value.findIndex(
        (x) => x.profileId === this.TEMP_PROFILE_ID
      );
      if (tempProfileIndex && tempProfileIndex !== -1) {
        this.profilesList.value.splice(tempProfileIndex, 1);
      }
      const tempProfileMapIndex = this.profileMap.userProfiles.findIndex(
        (x) => x.profileId === this.TEMP_PROFILE_ID
      );
      if (tempProfileMapIndex && tempProfileMapIndex !== -1) {
        this.profileMap.userProfiles.splice(tempProfileMapIndex, 1);
      }
    }
  }

  /**
   * Saves the currently selected profile into the db.
   */
  public saveCurrentProfile() {
    this.toggleSpinner(true);
    const isUpdate = !!this.currentProfile.value?.profileId;
    if (isUpdate) {
      const platformProfile = this.convert2PlatformProfile(
        <FilterProfile>this.currentProfile.value
      );
      this.apiService.updateProfile(platformProfile).subscribe((result) => {
        this.handleProfileUpdateResponse(this.currentProfile.value, result);
      });
    } else {
      this.saveCurrentAsNewProfile();
    }
  }

  /**
   * Returns a copy of the given FilterProfile as a Platform
   * User Service Profile. All fields that are inherited from
   * the client profile are ignored.
   */
  public convert2PlatformProfile(
    profile: FilterProfile
  ): PlatformFilterProfile {
    let cleanProfile = this.getCleanProfile(profile);
    cleanProfile = FilterProfileMap.handleExclusionKeys(cleanProfile);
    const platformProfile = PlatformFilterProfile.ingestFilterProfile(
      cleanProfile,
      this.platformProfileMap
    );
    return platformProfile;
  }

  /**
   * Saves the current profile as a new filter profile.
   */
  public saveCurrentAsNewProfile() {
    this.saveNewProfile(this.currentProfile.value);
  }

  public deleteCurrentProfile() {
    const canDelete = !!this.currentProfile.value?.profileId;
    if (canDelete) {
      this.apiService
        .deleteProfile(<number>this.currentProfile.value?.profileId)
        .subscribe((response) => {
          if (response) {
            const index = this.profilesList.value?.indexOf(
              <FilterProfile>this.currentProfile?.value
            );
            if (index !== -1) {
              this.profilesList.value.splice(index, 1);
              this.currentProfile.next(undefined);
            }
          }
          this.showToast('delete', !!response);
        });
    } else {
      this.showToast('delete', false);
    }
  }

  /**
   * Calls the api multiple times on the PUT endpoint to update profiles.
   */
  public updateManyProfiles(profiles: FilterProfile[]): Observable<boolean> {
    return new Observable<boolean>((obs) => {
      if (profiles && profiles.length) {
        try {
          const observables = [];
          profiles.forEach((profile) => {
            const platformProfile = this.convert2PlatformProfile(profile);
            observables.push(this.apiService.updateProfile(platformProfile));
          });
          zip(...observables).subscribe((restults) => {
            const strRes = restults ? JSON.stringify(restults) : '';
            const success =
              !strRes.includes('error') && !strRes.includes('not authorized');
            obs.next(success);
            obs.complete();
          });
        } catch (err) {
          this.showToast('update', false);
          obs.error(err);
        }
      } else {
        obs.next(null);
        obs.complete();
      }
    });
  }

  /**
   * Updates all elements within the given list of modified profiles, and
   * deletes all profiles wich ids are contained within the given list of
   * ids to delete.
   */
  public updateSomeDeleteOthers(
    modifiedProfiles: FilterProfile[],
    idsToDelete: number[]
  ): Observable<any> {
    // Encapsulated observable result into another observable to avoid triggering the content of the zippedObs twice.
    const obs2Return = new Observable((obs) => {
      const deleteObs = this.apiService.deleteManyProfiles(idsToDelete); // 1) Call api to delete using idsToDelete
      const updateObs = this.updateManyProfiles(modifiedProfiles); // 2) Call api to set new default
      const zippedObs = zip(deleteObs, updateObs);
      zippedObs.subscribe(
        ([deleteResult, updateResult]) => {
          if (deleteResult !== null) {
            this.showToast('delete', !!deleteResult);
          }
          if (updateResult !== null) {
            this.showToast('update', true);
          }
          obs.next();
          obs.complete();
          //this.updateLocalProfiles(modifiedProfiles, idsToDelete);
        },
        () => {
          this.showToast('update', false);
          obs.next();
          obs.complete();
        }
      );
    });
    return obs2Return;
  }

  /**
   * Shows a toast with text that's specific for filter profiles.
   */
  public showToast(source: ToastSource, success: boolean) {
    const toastType = success ? 'success' : 'error';
    const title = this.translateService.instant(
      `main.filters.profiles.toasts.titles.${source}`
    );
    const body = this.translateService.instant(
      `main.filters.profiles.toasts.${toastType}.${source}`
    );
    if (success) {
      this.toasterService.success(title, body);
    } else {
      this.toasterService.error(title, body);
    }
  }

  /**
   * Builds a Filter profile with the given set of filter configurations.
   */
  public buildFilterProfile(
    profileName: string,
    isDefault: boolean,
    rawFilterProfileData: RawFilterProfile
  ): FilterProfile {
    let mappedProfile = {};
    if (rawFilterProfileData) {
      mappedProfile = {
        profileName,
        isDefault,
        months: rawFilterProfileData?.months,
        monthsCode: rawFilterProfileData?.monthsCode,
        currencySymbol: rawFilterProfileData?.currencySymbol,
      };

      // Map label based fields
      const propertyMap = FilterProfile.getLabel2PropertyMap();
      Object.keys(propertyMap).forEach((label) => {
        const field = propertyMap[label];
        mappedProfile[label] = rawFilterProfileData[field];
      });
    }
    const filterProfile = Object.assign(new FilterProfile(), mappedProfile);
    return filterProfile;
  }

  /**
   * Saves the given profile into the DB and updates the profiles
   * list afterwards.
   */
  public saveNewProfile(filterProfile: FilterProfile) {
    this.toggleSpinner(true);
    this.ensureDefault(filterProfile);
    const platformProfile = this.convert2PlatformProfile(filterProfile);
    this.apiService.saveNewProfile(platformProfile).subscribe((newProfile) => {
      this.handleProfileCreationResponse(newProfile);
    });
  }

  /**
   * After saving a new profile into the db, adds the new profile to
   * the profiles list and updates the current profile.
   */
  private handleProfileCreationResponse(saveResponse: any): void {
    const profileWasCreated =
      saveResponse &&
      saveResponse.profile_id &&
      !JSON.stringify(saveResponse).includes('error');
    if (profileWasCreated) {
      this.loadProfiles(saveResponse.profile_id, true);
    } else {
      this.toggleSpinner(false);
      throw new Exception('Error creating profile');
    }
  }

  /**
   * After updating a profile, adds the new data to the profiles list
   * and updates the current profile.
   */
  private handleProfileUpdateResponse(
    updatedProfile: FilterProfile,
    updateResponse: PlatformFilterProfile
  ): void {
    const updateSuccessful =
      !!updateResponse &&
      updateResponse.profile_id &&
      !JSON.stringify(updateResponse).includes('error');
    this.loadProfiles(updateResponse.profile_id, true);
    this.showToast('update', updateSuccessful);
  }

  /**
   * Makes sure that the given FilterProfile is the only default one
   * by fetching the current default profile and turning it into a
   * non-default profile. This is used then creating new profiles
   * that are set as default upon creation.
   * It's not needed to call the API for this anymore since the API
   * already looks for defaults and sets them as false when a new
   * default profile is set.
   */
  private ensureDefault(filterProfile: FilterProfile): void {
    if (filterProfile.isDefault) {
      const found = this.profilesList.value.find(
        (x) => x.isDefault === true && x.profileId !== filterProfile.profileId
      );
      if (found) {
        found.isDefault = false;
      }
    }
  }

  /**
   * Calls all suscriptors of the profiles List to forcefully
   * notify changes.
   */
  public notifyProfileListSuscriptors() {
    this.profilesList.next(this.profilesList.value);
  }

  /**
   * Calls all suscriptors of the current profile to forcefully
   * notify changes.
   */
  public notifyCurrentProfileSuscriptors() {
    this.currentProfile.next(this.currentProfile.value);
  }

  /**
   * Shows or hides the rdo-filter-profile-spinner component based
   * on the given value.
   */
  public toggleSpinner(show: boolean): void {
    this.showSpinner.next(!!show);
  }

  /**
   * Updates the current filter profile without triggering suscriptors
   * (unless the current profile is null).
   */
  public runSilentCurrentProfileUpdate(rawFilterProfileData: RawFilterProfile) {
    const noProfileSelectedStr = this.translateService.instant(
      'main.filters.profiles.list.no_selected'
    );
    const profileName = this.currentProfile.value
      ? this.currentProfile.value.profileName
      : noProfileSelectedStr;
    const isDefault = this.currentProfile.value
      ? this.currentProfile.value.isDefault
      : false;
    const profile = this.buildFilterProfile(
      profileName,
      isDefault,
      rawFilterProfileData
    );
    if (this.currentProfile.value) {
      Object.keys(profile).forEach((key) => {
        this.currentProfile.value[key] = profile[key];
      });
    } else {
      // if the current profile is null, trigger a regular update.
      const emptyProfile = new FilterProfile();
      Object.assign(emptyProfile, profile);
      this.currentProfile.next(emptyProfile);
    }
  }

  public buildFilterProfileFromRawData(rawFilterProfileData: RawFilterProfile) {
    const noProfileSelectedStr = this.translateService.instant(
      'main.filters.profiles.list.no_selected'
    );
    const profileName = this.currentProfile.value
      ? this.currentProfile.value.profileName
      : noProfileSelectedStr;
    const isDefault = this.currentProfile.value
      ? this.currentProfile.value.isDefault
      : false;
    const profile = this.buildFilterProfile(
      profileName,
      isDefault,
      rawFilterProfileData
    );
    const newProfile = new FilterProfile();
    Object.assign(newProfile, profile);
    return newProfile;
  }

  /**
   * Updates a value within the current profile without triggering a
   * subscriptor. If the updated profile is also the client one, then
   * the client profile reference is also updated.
   */
  public updateCurrentProfileValue(codeName: string, value: any) {
    const currentIsClientProfile = this.currentProfile.value.profileId === -1;
    if (currentIsClientProfile) {
      this.clientProfile.value[codeName] = value;
    }
    this.currentProfile.value[codeName] = value;
    if (codeName === CODENAMES.CN_MONTHS) {
      if (this.currentProfile.value.profileId === -1) {
        this.platformProfileMap.system.filter(
          (r) => r.field_name === CODENAMES.CN_MONTHS
        )[0].default_selected_values = value;
      } else {
        const profileMap = this.platformProfileMap.user_defined.find(
          (x) => x.profile_id === this.currentProfile.value.profileId
        );
        if (profileMap && profileMap.fields && profileMap.fields.length) {
          profileMap.fields.filter(
            (r) => r.field_name === CODENAMES.CN_MONTHS
          )[0].default_selected_values = value;
        }
      }
    }
  }
}
