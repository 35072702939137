import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as auth0 from 'auth0-js';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { TokenInfo } from '../../models/token.info';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { QueryParams } from '../http/query-params';
import { switchMap } from 'rxjs/operators';
import { SelectableClient } from '../../../app/models/selectableclient';
import { ClientSelectionDialogData } from '../../../app/models/client-selection-dialog-data';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogCocustnfig,
} from '@angular/material/legacy-dialog';
import { ClientSelectorComponent } from './../../../app/client-selector/client-selector.component';
import { UserInfoView } from './../../models/user-info-view';
import { UnauthorizedComponent } from '../../../app/unauthorized/unauthorized.component';
import { LocaleService } from './../../core/services/locale.service';
import { v4 as uuidv4 } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { MatDialogConfig } from '@angular/material/dialog';
import * as Sentry from '@sentry/angular';
import { ErrorService } from '../errors/error.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public static readonly IMPERSONATED_USER_ID_CLAIM =
    'http://urn:rouse:identity:claims:currentlyimpersonatingid';
  public static readonly IMPERSONATED_USER_FULL_NAME_CLAIM =
    'http://urn:rouse:identity:claims:currentlyimpersonating';
  private auth0PkceClient: Auth0Client;
  private auth0ImplicitClient = null;

  public Authenticated = new BehaviorSubject(false);
  public AuthTokenObtained = new BehaviorSubject(false);
  public isauthenticationhandled: boolean = false;
  public isauthenticated: boolean = false;
  public tokenInfo: TokenInfo;
  public profile = new BehaviorSubject<any>(null);
  public _userInfoView: UserInfoView = <UserInfoView>{
    HasClientAccessToDownloads: false,
    FullName: '...',
    HasClientAccessToExportData: false,
  };
  public userInfoView = of(this._userInfoView);
  public selectedClientId = new BehaviorSubject<number>(undefined);

  public showHourlyRate: boolean;
  public reportAccesByMqaRole: boolean;

  public get selectableClients(): Array<SelectableClient> {
    let result = null;
    if (this._userInfoView.SelectableClients) {
      result = this._userInfoView.SelectableClients;
    } else {
      const item = new SelectableClient(
        undefined,
        '',
        '',
        '',
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        [],
        false
      );
      result = new Array({ item });
    }
    return result;
  }

  constructor(
    private router: Router,
    private httpclient: HttpClient,
    private materialdialog: MatDialog,
    private localeService: LocaleService,
    private toasterService: ToastrService,
    private errorService: ErrorService
  ) {
    if (environment.isPkceFlowMode === false) {
      const options = {
        allowAutocomplete: true,
        rememberLastLogin: true,
        allowedConnections: ['twitter', 'facebook', 'linkedin'],
      };
      this.auth0ImplicitClient = new auth0.WebAuth({
        clientID: environment.clientId,
        domain: environment.domain,
        responseType: environment.responseType,
        redirectUri: this.getCallBackUrl(),
        scope: environment.scope,
        audience: environment.audience,
        allowAutocomplete: true,
        rememberLastLogin: true,
        allowedConnections: ['twitter', 'facebook', 'linkedin'],
      });
    }
    this.selectedClientId.subscribe((clientid) => {
      if (clientid) {
        if (localStorage.getItem('redirectURL')) {
          if (!this._userInfoView.HasClientAccessToDownloads) {
            this.toasterService.error(
              'Download Error',
              'It was not possible to download the file'
            );
            this.router.navigate(['/summary']);
          }
          const redirectURL = new URL(localStorage.getItem('redirectURL'));
          this.router.navigateByUrl(redirectURL.pathname + redirectURL.search);
          localStorage.removeItem('redirectURL');
        } else if (
          !this._userInfoView.SelectedClient.RDOAccess &&
          this._userInfoView.HasClientAccessToDownloads
        ) {
          this.router.navigate(['/downloads']);
        } else {
          this.router.navigate(['/summary']);
        }
      }
    });
  }

  public async getAuth0PkceClient(): Promise<Auth0Client> {
    if (!this.auth0PkceClient) {
      const config = {
        client_id: environment.clientId,
        domain: environment.domain,
        responseType: environment.responseType,
        redirectUri: this.getCallBackUrl(),
        scope: environment.scope,
        audience: environment.audience,
      };
      this.auth0PkceClient = await createAuth0Client(config);
      const isAuthenticated = await this.auth0PkceClient.isAuthenticated();
      this.Authenticated.next(isAuthenticated); // Provide the current value of isAuthenticated
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      this.Authenticated.subscribe(async (isAuthenticated) => {
        // Whenever isAuthenticated changes, provide the current value of `getUser`
        if (isAuthenticated) {
          // ***** authentication has been detected in pkce mode *****
          // get the basic information need to support the application (the access token and related information, the user information and accessible clients)
          this.isauthenticated = true;
          this.profile.next(await this.auth0PkceClient.getUser());
          const accesstoken = await this.auth0PkceClient.getTokenSilently();
          const identitytoken = '';
          const tokeninfo = new TokenInfo(identitytoken, accesstoken);
        } else {
          this.profile.next(null);
        }
      });
    }
    return this.auth0PkceClient;
  }

  public async login(locale: string = null) {
    const supportedLocale = this.localeService.getSupportedLocale(
      this.localeService.getLocale()
    );
    if (environment.isPkceFlowMode === true) {
      this.auth0PkceClient = await this.getAuth0PkceClient();
      this.Authenticated.subscribe((value) => {
        this.isauthenticated = value;
      });
      this.profile.subscribe((profile) => {
        this.profile = profile;
      });
      await this.auth0PkceClient.loginWithRedirect({
        redirect_uri: this.getCallBackUrl(),
        ui_locales: supportedLocale,
      });
    } else {
      // eslint-disable-next-line
      console.log('using auth0 supported locale: ' + supportedLocale);
      this.auth0ImplicitClient.authorize({
        ui_locales: supportedLocale,
      });
    }
  }

  private handleSelectedClient(selectedclient: SelectableClient) {
    this._userInfoView.updateSelectedClient(selectedclient);
    this.localeService.setLocale(selectedclient.Lang);
    this.selectedClientId.next(selectedclient.ClientID);
  }

  public setLocaleAfterLogin(lang: string) {
    if (lang) {
      this.localeService.setLocale(lang);
    }
  }

  public enablePerformanceMonitoring = () => {
    return (
      environment.platform === 'Production' &&
      this._userInfoView &&
      this._userInfoView.ImpersonatedOrCurrentUserEmail &&
      environment.ukTestingNames.includes(
        this._userInfoView.ImpersonatedOrCurrentUserEmail.toLocaleLowerCase()
      )
    );
  };

  public startSentryTransaction = (name: string) => {
    // used for Automated testing only for some reason
    // routes arent traced by default when the automated tests are run
    if (!this.enablePerformanceMonitoring()) {
      return;
    }
    const transaction = Sentry.startTransaction({
      name: name,
      tags: {
        userId: this.getUserId(),
        clientId: this.getClientId(),
      },
    });
    if (transaction) {
      Sentry.getCurrentHub().configureScope((scope) =>
        scope.setSpan(transaction)
      );
      const span = transaction.startChild({
        op: name,
        data: {},
      });
      Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(span));
    }
  };

  public finishSentryTransaction = () => {
    if (!this.enablePerformanceMonitoring()) {
      return;
    }
    try {
      const span = Sentry.getCurrentHub().getScope().getSpan();
      if (span) {
        span.finish();
      }
      const transaction = Sentry.getCurrentHub().getScope().getTransaction();
      if (transaction) {
        transaction.finish();
        Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(null));
      }
    } catch (error) {
      this.errorService.logSentryError(error);
    }
  };

  public handleAuthentication(): void {
    this.auth0ImplicitClient.parseHash((err, authResult) => {
      this.isauthenticationhandled = true;
      const isproceed = true;
      if (isproceed) {
        if (authResult && authResult.accessToken && authResult.idToken) {
          // ***** authentication has been detected in implicit mode *****
          const tokenInfo = new TokenInfo(
            authResult.IdToken,
            authResult.accessToken
          );
          this.tokenInfo = tokenInfo;
          try {
            this.AuthTokenObtained.next(true);
            this.httpget('/identity/users/me').subscribe(
              (userInfo: any) => {
                if (userInfo) {
                  let selectedclient: SelectableClient = null;
                  this._userInfoView = new UserInfoView(userInfo, tokenInfo); // this will most of the user information except the selected client
                  this.setLocaleAfterLogin(this._userInfoView.lang);
                  if (
                    this._userInfoView.SelectableClients &&
                    this._userInfoView.SelectableClients.length > 1
                  ) {
                    // multi-client scenario
                    this.promptClientSelection().subscribe((selectedclient) => {
                      if (selectedclient) {
                        this.handleSelectedClient(selectedclient);
                      }
                      this.setAuthenticated(true);
                    });
                  } else {
                    // single-client scenario
                    if (this._userInfoView.SelectableClients.length === 1) {
                      selectedclient = this._userInfoView.SelectableClients[0];
                      this.handleSelectedClient(selectedclient);
                    } else {
                      // must be 0 length, user has no access to any client
                      this.blockUnauthorizedUser();
                    }
                    this.setAuthenticated(true);
                  }
                }
              },
              (error) => {
                if (error.status === '401') {
                  // window.location.href = 'unauthorized';  // this is a cleaner presentation than router.navigate
                  this.blockUnauthorizedUser();
                }
              }
            );
          } catch {}
        } else if (err) {
          console.error(err);
        }
      }
    });
  }

  private setAuthenticated(value: boolean): void {
    this.isauthenticated = value;
    this.Authenticated.next(value);
  }

  public promptClientSelection = (): Observable<SelectableClient> => {
    const result = new BehaviorSubject<SelectableClient>(undefined);
    const dialogConfig = new MatDialogConfig();
    const dialogData = new ClientSelectionDialogData(
      this._userInfoView.SelectableClients,
      this._userInfoView.SelectedClient
        ? this._userInfoView.SelectedClient.ClientID
        : null
    );
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = dialogData;
    this.materialdialog
      .open(ClientSelectorComponent, dialogConfig)
      .afterClosed()
      .subscribe((dialogresponse) => {
        if (dialogresponse != null && dialogresponse.selectedClient != null) {
          result.next(dialogresponse.selectedClient);
        } else {
          //
        }
        result.complete();
      });
    return result.asObservable();
  };

  public blockUnauthorizedUser = () => {
    const dialogConfig = new MatDialogConfig();
    const dialogData = null;
    dialogConfig.disableClose = false; // true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = dialogData;
    this.materialdialog
      .open(UnauthorizedComponent, dialogConfig)
      .afterClosed()
      .subscribe((dialogresponse) => {
        this.router.navigate(['signout']);
      });
  };

  public getAuthToken(): Observable<TokenInfo> {
    let result = of(<TokenInfo>null);
    if (
      this._userInfoView.tokenInfo &&
      this._userInfoView.tokenInfo.ValidTo > new Date()
    ) {
      result = of(this._userInfoView.tokenInfo);
    } else {
      if (this.tokenInfo && this.tokenInfo.ValidTo > new Date()) {
        result = of(this.tokenInfo);
      }
    }
    return result;
  }

  public getSentryTraceId(): Observable<string> {
    const result: string = uuidv4();

    return of(result);
  }

  public renewTokens(): void {
    if (environment.isPkceFlowMode === true) {
      //
    } else {
      this.auth0ImplicitClient.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          //
        } else if (err) {
          this.logout();
        }
      });
    }
  }

  public logout(): void {
    // eslint-disable-next-line
    // @ts-ignore
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      document.execCommand('ClearAuthenticationCache');
    }
    if (environment.isPkceFlowMode === true) {
      this.auth0PkceClient.logout({ returnTo: window.location.origin });
    } else {
      this.auth0ImplicitClient.logout({ returnTo: window.location.origin });
    }
  }

  /**********************************************************************************************
   * todo - consolidate (somehow) with rdohttp service.  at present causes circular reference
   *
   */
  public httpget = (...args: any[]): Observable<Record<any, any>> => {
    const apiurl = environment.apiUrl;
    return this.getAuthToken().pipe(
      switchMap((token) => {
        const endpoint = args[0].indexOf('/') === 0 ? args[0] : '/' + args[0];
        args[1] = args[1] || {};
        let searchParams = new HttpParams();
        if (args.length > 1 && args[1]) {
          searchParams = this.toHttpParams(args[1].search as QueryParams);
        }
        return this.baseApiUrl.pipe(
          switchMap((apiUrl) => {
            const url = apiUrl + endpoint;
            const headers = new HttpHeaders().set(
              'Authorization',
              `Bearer ${token.AccessToken}`
            );
            const result = this.httpclient.get(url, {
              headers,
              params: searchParams,
              withCredentials: true,
            });
            return result;
          })
        );
      })
    );
  };

  public hasAccessToCustomGrids = (
    showCustomGrids: boolean,
    isTrial: boolean
  ) => {
    if (
      isTrial ||
      !showCustomGrids ||
      !this._userInfoView.SelectedClient ||
      !this._userInfoView.SelectedClient.RDOAccess ||
      !this._userInfoView.SelectedClient.HasCustomGridsData
    ) {
      return false;
    }

    if (
      this._userInfoView.HasGeographyFullAccess &&
      !this._userInfoView.HasSalesRepRole
    ) {
      return true;
    }
    if (
      this._userInfoView.SelectedClient.RDOCustomGridsEditor ||
      this._userInfoView.SelectedClient.RDOClientCustomGridsEditor
    ) {
      return true;
    }

    return false;
  };

  private get baseApiUrl(): Observable<string> {
    const apiurl = environment.apiUrl;
    return of(apiurl);
  }

  private getCallBackUrl = (): string => {
    let result: string = '';
    const prefix: string = window.location.origin;
    result = prefix + '/callback';
    return result;
  };

  private toHttpParams = (params: QueryParams): HttpParams => {
    if (!params) {
      return new HttpParams();
    }
    let httpParams = new HttpParams();
    Array.from(params.paramsMap.entries()).forEach((entry) => {
      if (entry[1].length === 1) {
        httpParams = httpParams.append(entry[0], entry[1][0]);
      } else {
        entry[1].forEach((item) => {
          httpParams = httpParams.append(entry[0], item);
        });
      }
    });
    if (sessionStorage.getItem('MultiClientId') != null) {
      httpParams = httpParams.append(
        'MultiClientId',
        sessionStorage.getItem('MultiClientId')
      );
    }
    return httpParams;
  };

  public getUserId = () => {
    if (this._userInfoView) {
      return this._userInfoView.Id;
    }
    return null;
  };

  /**
   * Returns the current UserId regardless of it
   * being impersonated or regular. The impersonator
   * id us not returned.
   */
  public getUserIdWithImpersonation = () => {
    return (
      this._userInfoView?.tokenInfo?.getClaimValue(
        AuthenticationService.IMPERSONATED_USER_ID_CLAIM
      ) || this._userInfoView.Id
    );
  };

  public getClientId = () => {
    if (this._userInfoView && this._userInfoView.SelectedClient) {
      return this._userInfoView.SelectedClient.ClientID;
    }
    return null;
  };

  public userIsTestUserImpersonator = () => {
    return this._userInfoView?.IsRouseTestUserImpersonator;
  };

  public userIsOrImpersonatesCGEditor = () => {
    // const theOldWay = this._userInfoView?.SelectedClient?.RDOCustomGridsEditor || this._userInfoView?.SelectedClient?.RDOClientCustomGridsEditor;
    return this._userInfoView
      ? this._userInfoView.IsOrImpersonatesCGEditor
      : false;
  };

  public hasGeographyFullAccess = () =>
    this._userInfoView?.HasGeographyFullAccess;
}
