import { Injectable } from '@angular/core';
import { SupportedLocaleDictionary } from './az-lang-support'; // acquired from auth0 current lang config
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LocaleService {
  public observableLocale = new BehaviorSubject<string>(undefined);
  private locale = null;
  private localeFoundOnInit = false;
  private readonly LOCALE = 'locale';
  private readonly DEFAULT_LANG = 'en-US';

  constructor() {
    this.initLocale();
  }

  public localeWasFoundOnInit() {
    return this.localeFoundOnInit;
  }

  private initLocale(): void {
    if (localStorage) {
      const localStorageLocale = localStorage.getItem(this.LOCALE);
      if (localStorageLocale) {
        this.locale = localStorageLocale;
        this.localeFoundOnInit = true;
      } else {
        this.localeFoundOnInit = false;
      }
    }

    if (!this.locale) {
      if (SupportedLocaleDictionary[window.navigator.language]) {
        this.locale = window.navigator.language;
      } else {
        this.locale = this.DEFAULT_LANG;
      }
    }
  }

  public getLocale(): string {
    return this.locale;
  }

  public getSupportedLocale(locale: string = this.locale): string {
    return SupportedLocaleDictionary[locale] || this.DEFAULT_LANG;
  }

  public setLocale(locale: string): string {
    this.locale = locale;
    this.observableLocale.next(locale);
    if (localStorage) {
      localStorage.setItem(this.LOCALE, locale);
    } else {
      console.warn('no localStorage available to save locale: ', this.locale);
    }

    return this.locale;
  }
}
